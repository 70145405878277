import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';
export async function get_active_class_grades() {
    try {
        const response = await fetch(HTTPURL + 'api/branches/get-class-grades');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_active_class_terms(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-class-term?year=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_class_courses(grade_id,term_id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-class-courses?grade_id=${grade_id}&term_id=${term_id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//add  class
export async function class_add(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/branches/class-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// get all subjects 
export async function class_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/branches/class-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function class_destroy(data,id) 
{
    try {
        const response = await fetch(HTTPURL + `api/branches/class-distroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// class edit 
export async function class_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/branches/class-edit/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// subject update 
export async function class_update(data,id)
{
    try {
        const response = await fetch(HTTPURL + `api/branches/class-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function student_all(id)
{
    try {
        const response = await fetch(HTTPURL + `api/branches/get-student-list?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const studentList = async (id) => 
    {
        try {
            const result = await student_all(id);
            // Assuming result is an array of objects with properties value and label
            const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.first_name }));
           console.log('transformedOptions',transformedOptions);
           
            return transformedOptions;
          } catch (error) {
            console.error('Error fetching data:', error);
            return error;
          }
    }