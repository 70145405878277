import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { text_book_destroy, get_text_books } from "../../../apis/TextBook";
import CustomTable from "../CustomTable";
import { getUserPermission } from "../../../Utils/Utils";

const AllTextBooks = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("TextBooks");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Book Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Term",
      selector: "term",
      sortable: true,
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await get_text_books();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, price, link, delete_status, subject, grade, term } =
          elem;
        return {
          no: `#${index + 1}`,
          name: name,
          price: price,
          link: link,
          status: delete_status == "1" ? "ACTIVE" : "INACTIVE",
          subject: subject,
          grade: `YEAR ${grade}`,
          term: term,
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/admin/course-managment/text-book/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() =>
                    destroy(id, delete_status == "1" ? 0 : 1, name)
                  }
                >
                  {" "}
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active  this text Book  ?"
            : "Are you sure you want to  inactive  this text Book  ? "
        }`
      );
      if (result) {
        await text_book_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Text Books List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/course-managment/text-book/add"
                >
                  Add Text Books
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllTextBooks;
