import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import {
  get_all_website_env_data,
  get_all_year_data,
  websiteEnv_destroy,
} from "../../../../apis/generalSetting/WebsiteEnv";
import CustomTable from "../../CustomTable";
import { getUserPermission } from "../../../../Utils/Utils";

const AllWebsiteEnv = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [id, setId] = useState("");
  const permissions = getUserPermission("WebsiteEnv");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Year",
      selector: "year",
      sortable: true,
    },
    {
      name: "State",
      selector: "state",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.view === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  //check for previous year
  const Year = new Date().getFullYear();
  useEffect(() => {
    async function get_data() {
      const result = await get_all_website_env_data(id);
      const yearList = await get_all_year_data();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, Current_year, state, delete_status } = elem;
        return {
          no: `#${index + 1}`,
          year: Current_year,
          state: state,
          status: delete_status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                Current_year >= Year ? (
                  <Link
                    class="edit-btn"
                    to={`/admin/administrator-setting/websiteEnv/edit/${id}`}
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </Link>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {permissions?.view === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/admin/administrator-setting/websiteEnv/view/${id}`}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() => destroy(id, delete_status == 1 ? 0 : 1, state)}
                >
                  {" "}
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setYearData(yearList?.data);
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status == 1
            ? "Are you sure you want to active  this website env ?"
            : "Are you sure you want to  inactive  this website env ? "
        }`
      );
      if (result) {
        await websiteEnv_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, [id]);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Environment Variables</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/administrator-setting/websiteEnv/add"
                >
                  Add Website Env
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="form-group">
              {/* <label>Year</label> */}
              <select
                className="form-select form-control "
                name="branch"
                onChange={(e) => setId(e.target.value)}
              >
                <option value="">CHOOSE YEAR</option>
                {yearData?.length > 0 ? (
                  yearData &&
                  yearData?.map((elem) => {
                    return (
                      <option value={elem?.current_year}>
                        {elem?.current_year}
                      </option>
                    );
                  })
                ) : (
                  <option>No result</option>
                )}
              </select>
            </div>
          </div>
          <CustomTable columns={columns} data={data} search={false} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllWebsiteEnv;
