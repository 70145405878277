import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { events_all, events_destroy } from "../../../apis/Events.jsx";
import DataLoader from "../../../Utils/DataLoader.jsx";
import CustomTable from "../CustomTable.jsx";
import { getUserPermission } from "../../../Utils/Utils.js";

const Events = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Events");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
    },
    {
      name: "Venue",
      selector: "venue",
      sortable: true,
    },
    {
      name: "Booking",
      selector: "offline_online_event",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 ||
    permissions?.delete === 1 ||
    permissions?.view === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await events_all();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          event_type,
          date,
          title,
          venue_name,
          offline_online_event,
          delete_status,
        } = elem;
        const formattedDate = date ? date.split("-").reverse().join("-") : "";
        return {
          no: `#${index + 1}`,
          type: event_type,
          date: formattedDate,
          title,
          venue: venue_name,
          offline_online_event,
          status: delete_status == "0" ? "INACTIVE" : "ACTIVE",
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.view === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/admin/administrator-setting/events/EventView/${id}`}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/administrator-setting/events/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() => destroy(id, delete_status == 1 ? 0 : 1, title)}
                >
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status == 1
            ? "Are you sure you want to active  this event ?"
            : "Are you sure you want to  inactive  this event ? "
        }`
      );
      if (result) {
        await events_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Event List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/administrator-setting/events/add"
                >
                  New Event
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default Events;
