import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// get all items
export async function get_text_books() {
    try {
        const response = await fetch(HTTPURL + 'api/text-book-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// get all textbook by subject_id
export async function get_text_books_subject_id(id) {
    try {
        const response = await fetch(HTTPURL + `api/text-book-subject-id?subject_id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

export async function add_text_book(data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + 'api/text-book-add', requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// // add item
// export async function add_text_book(data) {
//     try {
//         const response = await fetch(HTTPURL + 'api/text-book-add', {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(data)
//         });
//         const responseData = await response.json();
//         if (responseData.status) {
//             // toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }


// item delete
export async function text_book_destroy(id,data) {
    try {
        const response = await fetch(HTTPURL + `api/text-book-destroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {    
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function text_book_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/text-book-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function text_book_update(id, data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + `api/text-book-update/?id=${id?.id}`,requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
