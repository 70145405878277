import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// get all items
export async function get_category() {
    try {
        const response = await fetch(HTTPURL + 'api/categories-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add item
export async function add_category(data) {
    try {
        const response = await fetch(HTTPURL + 'api/category-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function category_destroy(id,data) {
    try {
        const response = await fetch(HTTPURL + `api/category-destroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function category_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/category-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function category_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/category-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
