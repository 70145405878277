import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add grade
export async function term_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/term-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




// get all term
export async function get_terms() {
    try {
        const response = await fetch(HTTPURL + 'api/terms-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all term by course
export async function get_course_terms(id) {
    try {
        const response = await fetch(HTTPURL + `api/course-all-term?course_id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all term
export async function get_terms_data() {
    try {
        const response = await fetch(HTTPURL + 'api/all-terms-data');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term edit 
export async function terms_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/term-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term update 
export async function term_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/term-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term delete
export async function term_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/term-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// add day
export async function day_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/day-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all days
export async function get_days() {
    try {
        const response = await fetch(HTTPURL + 'api/days-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// get all days
export async function get_states() {
    try {
        const response = await fetch(HTTPURL + 'api/state-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// term edit 
export async function days_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/day-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term update 
export async function day_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/day-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term delete
export async function day_destroy(id,data) {
    try {
        const response = await fetch(HTTPURL + `api/day-destroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




// get insert id
export async function insertId() {
    try {
        const response = await fetch(HTTPURL + `api/get-last-insert-id`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

export async function subject_all_by_grade_id(id)
{
    try {
        const response = await fetch(HTTPURL + `api/get-subject-by-grade/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function subject_all_by_course_id(id)
{
    try {
        const response = await fetch(HTTPURL + `api/get-subject-by-course/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// get steps of learning
export async function get_step_of_learning() {
    try {
        const response = await fetch(HTTPURL + 'api/step-of-learnig');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}


// get steps of learning
export async function all_test_kind() {
    try {
        const response = await fetch(HTTPURL + 'api/all-test-kind');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}
export async function course_list() {
    try {
        const response = await fetch(HTTPURL + 'api/course-list');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}


export async function movie_type_by_subjectId(id)
{
    try {
        const response = await fetch(HTTPURL + `api/movie-data/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function skill_by_subjectId(id)
{
    try {
        const response = await fetch(HTTPURL + `api/skill-data/?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function test_type_list(year,grade_id,term_id)
{
    try {
        const response = await fetch(HTTPURL + `api/test-type-list/?year=${year}&grade=${grade_id}&term=${term_id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// get all term
export async function get_terms_by_year(id) {
    try {
        const response = await fetch(HTTPURL + `api/year-terms-all?year=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get class by branchid
export async function get_class_by_branch(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-classes-list?branch_id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
