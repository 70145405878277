import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { events_bookings } from "../../../apis/Events";
import CustomTable from "../CustomTable";
import { getUserPermission } from "../../../Utils/Utils";

const EventBookings = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const permissions = getUserPermission("Grades");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Event",
      selector: "event",
      sortable: false,
    },
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Mobile",
      selector: "mobile",
      sortable: false,
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Child Name",
      selector: "child_name",
      sortable: false,
    },
    {
      name: "Event type",
      selector: "event_type",
      sortable: false,
    },
    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: false,
    // },
    // permissions?.edit === 1 || permissions?.delete === 1
    //   ? {
    //       name: "Action",
    //       selector: "action",
    //       sortable: false,
    //     }
    //   : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await events_bookings();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          first_name,
          last_name,
          mobile,
          email,
          child_name,
          event_type,
          event_name,
          event_id,
        } = elem;
        return {
          no: `#${index + 1}`,
          name: `${first_name} ${last_name}`,
          mobile: mobile,
          email: email,
          child_name: child_name,
          event_type: event_type,
          event: (
            <Link
              className="text-black"
              to={`/admin/administrator-setting/events/EventView/${event_id}`}
            >
              {event_name}
            </Link>
          ),
          //name:name,
          //   action: (
          //     <div data-tag="allowRowEvents">
          //       {permissions?.edit === 1 ? (
          //         <button
          //           onClick={() => {
          //             setDuplicateItemId(id);
          //             setIsModalOpen(true);
          //             setStatus("grade");
          //           }}
          //           className="dlt-btn"
          //         >
          //           {" "}
          //           <i class="fa fa-pencil" aria-hidden="true"></i>
          //         </button>
          //       ) : (
          //         ""
          //       )}
          //       {permissions?.delete === 1 ? (
          //         <button className="dlt-btn" onClick={() => destroy(id)}>
          //           {" "}
          //           <i class="fa fa-trash" aria-hidden="true"></i>
          //         </button>
          //       ) : (
          //         ""
          //       )}
          //     </div>
          //   ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    get_data();
  }, [isModalOpen]);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Event booking list</h4>
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventBookings;
