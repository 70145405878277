import React, { useEffect, useState } from "react";
const AddHomeWork = ({ setHomeWork, skillData }) => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    const totalUnits = parseInt(localStorage.getItem("totalUnits") || "1", 10);
    const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
      unit_id: index + 1,
      homeWork: skillData.map((skillData) => ({
        skill: "",
        title: "",
        tstm_guid: "",
      })),
    }));
    setForms(initialForms);
    setHomeWork(initialForms);
  }, [skillData]);
  const handlehomeWorkInputChange = (e, unitIndex, movieIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].homeWork[movieIndex] = {
      ...updatedForms[unitIndex].homeWork[movieIndex],
      [name]: value,
    };
    setForms(updatedForms);
    setHomeWork(updatedForms);
  };
  return (
    <div className="accordion" id="accordionExample">
      {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Skill Type</th>
                      <th>Title</th>
                      <th>TSTM GUID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.homeWork.map((homeWork, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select"
                            name="skill"
                            value={homeWork.skill}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="" >
                              Select Type
                            </option>
                            {skillData.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.skill}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={homeWork.title}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="tstm_guid"
                            value={homeWork.tstm_guid}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AddHomeWork;
