import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { get_states, term_update, terms_edit } from "../../../apis/Common";
import { Button } from "semantic-ui-react";
import { branch_all, state_branch_all } from "../../../apis/Branches";
const EditTerms = ({
  isModalOpen,
  setIsModalOpen,
  duplicateItemId,
  setStatus,
}) => {
  // const [checkValidation, setCheckValidation] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      state: "" // initial empty value
    }
  });
  const [stateID, setStateId] = useState("");
  const [statelist, setStateList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const maxDate = `${new Date().getFullYear()}-12-31`;
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    console.log("going");
    // if (!checkValidation) {
    //     console.log('invalid');
    // }
    // else {
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    const weeksBetweenDates = Math.round(
      (endDate - startDate) / (7 * 24 * 60 * 60 * 1000)
    );
    const formattedData = {
      ...data,
      start_date: data.start_date,
      end_date: data.end_date,
      max_week: weeksBetweenDates,
    };
    let result = await term_update(duplicateItemId, formattedData);
    if (result?.status) {
      setIsModalOpen(false);
      setStatus("");
      window.location.reload();
      return navigate("/admin/term/all");
    }
    setIsSubmitting(false);
  };
  // }
  useEffect(() => {
    async function get_data() {
      const result = await terms_edit(duplicateItemId);
      if (result?.data?.length > 0) {
        const { name, start_date, end_date, status, state, branch, max_week,branch_id } =
        result?.data[0];
        setValue("name", name);
        setValue("start_date", start_date);
        setValue("end_date", end_date);
        setValue("status", status);
        setStateId(state);
         setValue("state", state);
        setValue("branch", branch_id);
        setValue("max_week", max_week);
      }
    }
    if (isModalOpen) {
      get_data();
    }
  }, [isModalOpen, duplicateItemId]);

  useEffect(() => {
    const fetchStateBranch = async () => {
      if (stateID) {
        const resp = await state_branch_all(stateID);
        const dataa = resp?.data;
        setBranchList(dataa);
      } else {
        const branchList = await branch_all();
        setBranchList(branchList?.data);
      }
    };

    fetchStateBranch();
  }, [stateID]);

  useEffect(() => {
    return setIsModalOpen(false);
  }, []);
  // const regexPattern = /^term\s?(1[0-2]|[1-9])$/;
  // const checkregex = (name) => {
  //     if (regexPattern.test(name)) {
  //         console.log("Valid string");
  //         setCheckValidation(true);
  //     } else {
  //         setCheckValidation(false);
  //     }
  // }
  useEffect(() => {
    const fetchData = async () => {
      const stateList= await get_states();
      setStateList(stateList?.data);
    };
    fetchData();
  }, []);
  return (
    <>
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden={isModalOpen}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">
                Edit Data
              </h5>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setStatus("");
                }}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Term Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Term Name"
                        {...register("name", { required: true })}
                      />
                    </div>
                    {errors.name && errors.name.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                    {/* {checkValidation ? '' : <span className="validation-error">Term name should be term and number between 0 to 12</span>} */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State *</label>
                      <select
                        className="form-select form-control"
                        // value={"New South Wales"}
                        {...register("state", { required: "This is required" })}
                        onChange={(e) => setStateId(e.target.value)}
                      >
                        {/* <option value="">Choose State</option> */}
                        {/* <option value="New south wales">New South Wales</option>
                        <option value="Australian Capital Territory">
                          Australian Capital Territory
                        </option>
                        <option value="Northern Territory">
                          Northern Territory
                        </option>
                        <option value="Queensland">Queensland</option>
                        <option value="South Australia">South Australia</option>
                        <option value="Tasmania">Tasmania</option>
                        <option value="Victoria">Victoria</option>
                        <option value="Western Australia">
                          Western Australia
                        </option> */}
                         {statelist?.length > 0
                        ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">State not available</option>,
                          ]}
                      </select>
                      {errors.state && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={show ? `col-md-6` : `col-md-6`}>
                    <div className="form-group">
                      <label>Branch</label>
                      <select
                        key={branchlist.length} // Add a key prop and update it when branchlist changes
                        className="form-select form-control"
                        name="grade"
                        {...register("branch")}
                      >
                        {branchlist?.length > 0
                          ? [
                              <option value="">Choose Branch</option>,
                              ...branchlist?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.branch_name}
                                  </option>
                                );
                              }),
                            ]
                          : [
                              // <option value="">Choose Branch</option>
                              //   ,
                              <option value="">Branch not available</option>,
                            ]}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Start Date *</label>
                      <input
                        type="date"
                        className="form-control"
                        pattern="dd-mm-yyyy"
                        placeholder="Enter Start Date"
                        min={today}
                     max="9999-12-31"
                        {...register("start_date", {
                          required: "Start date is required!",
                          validate: (value) => {
                            const selectedDate = new Date(value);
                            const todayDate = new Date();
                               return selectedDate > todayDate || "Please select a future date.";
                            // const year = value?.split("-")[0];
                            // if (year.length !== 4) {
                            //   return "Year must be 4 digits.";
                            // }
                          },
                        })}
                      />
                    </div>
                    {errors.start_date &&
                      errors.start_date.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>End Date *</label>
                      <input
                        type="date"
                        className="form-control"
                        pattern="dd-mm-yyyy"
                        max="9999-12-31"
                        placeholder="Enter End Date"
                        min={today}
                       // max={maxDate}
                        {...register("end_date", {
                          required: "End date is required!",
                          validate: (value) => {
                            const selectedDate = new Date(value);
                            const todayDate = new Date();
                            return (
                              selectedDate > todayDate ||
                              "Please select a future date."
                            );
                          },
                        })}
                      />
                    </div>
                    {errors.end_date && errors.end_date.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>status</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status")}
                          id="inlineRadio1"
                          value="1"
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Active
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status")}
                          id="inlineRadio2"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    type="submit"
                    className="btn button"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditTerms;
