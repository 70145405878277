import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { branch_all } from "../../../../apis/Branches";
import {
  get_permissions,
  update_role,
  edit_role,
  delete_role,
} from "../../../../apis/generalSetting/RolePermission";
import { useEffect, useState } from "react";
import { getUserPermission } from "../../../../Utils/Utils";
const EditRolePermission = ({ isModalOpen, setIsModalOpen, id }) => {
  const [branchlist, setBranchList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [editPermissionsState, setEditPermissionsState] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const permission = getUserPermission("RolePermission");
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    const fetchData = async () => {
      const res = await branch_all();
      const permission = await get_permissions();
      setBranchList(res?.data);
      setPermissions(permission?.data?.modules);
    };
    const editData = async () => {
      const res = await edit_role(id);
      setValue("role", res?.data?.rolePermission?.role);
      setValue("description", res?.data?.rolePermission?.description);
      setValue("branch_id", res?.data?.rolePermission?.branch_id);
      setEditPermissionsState(res?.data?.rolePermission?.modules);
      let AllRoleDetail = res?.data?.rolePermission;
      if (AllRoleDetail && AllRoleDetail.modules) {
        const initialCheckedPermissions = {};
        AllRoleDetail.modules?.forEach((module) => {
          initialCheckedPermissions[module.id] = {};
          module.permission?.forEach((permission) => {
            initialCheckedPermissions[module.id][permission.permission_id] =
              permission.status;
          });
        });
        setCheckedPermissions(initialCheckedPermissions);
      }
    };
    if (id) {
      editData();
    }
    fetchData();
  }, [id]);
  const toggleEditPermission = (moduleId, permId, isChecked) => {
    setCheckedPermissions((prevState) => ({
      ...prevState,
      [moduleId]: {
        ...prevState[moduleId],
        [permId]: isChecked,
      },
    }));
  };

  const toggleAllEditPermissions = (moduleId, isChecked) => {
    setCheckedPermissions((prevState) => {
      const updatedState = { ...prevState };
      const modulePermissions = editPermissionsState.find(
        (m) => m.id === moduleId
      )?.permission;

      if (modulePermissions) {
        updatedState[moduleId] = modulePermissions.reduce((acc, perm) => {
          acc[perm.permission_id] = isChecked ? 1 : 0;
          return acc;
        }, {});
      }
      return updatedState;
    });
  };
  const onSubmit = async (data) => {
    const permissionsPayload = [];
    editPermissionsState.forEach((module) => {
      module.permission.forEach((permission) => {
        if (checkedPermissions[module.id]?.[permission.permission_id]) {
          permissionsPayload.push({
            permission_id: permission.permission_id,
            status: checkedPermissions[module.id]?.[permission.permission_id]
              ? 1
              : 0,
          });
        }
      });
    });
    const formData = {
      role: data.role,
      description: data.description,
      branch_id: data.branch_id,
      permissions: permissionsPayload,
    };
    let result = await update_role(formData, id);
    if (result.status) {
      setIsModalOpen(false);
    }
  };
  const deleteButton = async () => {
    const result = window.confirm("Are you sure you want to delete this role?");
    if (result) {
      const data = await delete_role(id);
      if (data.status) {
        setIsModalOpen(false);
      }
    }
  };
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        shouldCloseOnOverlayClick={false}
        contentLabel="Edit Grade"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <h1 className="modal-title fs-5"> Edit role</h1>
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mt-3">
              <label className="label-txt">Role</label>
              <input
                type="text"
                placeholder="Manager"
                className="form-control"
                id="inputtext_1"
                {...register("role", { required: "true" })}
              />
              {errors.role && errors.role.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Branch list</label>
              <select
                className="form-select"
                {...register("branch_id", { required: "true" })}
              >
                <option value="">Choose Branch</option>
                {branchlist?.length > 0 ? (
                  branchlist &&
                  branchlist?.map((elem) => {
                    return (
                      <option value={elem?.id}>{elem?.branch_name}</option>
                    );
                  })
                ) : (
                  <option>No result</option>
                )}
              </select>
              {errors.branch_id && errors.branch_id.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="label-txt">Description</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="4"
                placeholder="Enter your message here..."
                {...register("description", { required: "true" })}
              ></textarea>
              {errors.description && errors.description.type === "required" && (
                <span className="validation-error"> This is required</span>
              )}
            </div>
          </div>

          <div className="accordionss">
            {editPermissionsState?.map((module) => {
              const allPermissionsChecked = Object.values(
                checkedPermissions[module.id] || {}
              ).every((status) => status === 1);
              return (
                <div className="accordion-item" key={module.id}>
                  <h2
                    className="accordion-header btnpos"
                    id={`heading${module.id}`}
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${module.id}`}
                      aria-expanded="true"
                      aria-controls={`collapse${module.id}`}
                    >
                      <div className="accrd-heading">{module.module_name}</div>
                    </button>
                    <div className="checkbox-onoff grades_boxbtn">
                      <input
                        type="checkbox"
                        id={`togglee${module.id}`}
                        checked={allPermissionsChecked}
                        onChange={(e) => {
                          toggleAllEditPermissions(module.id, e.target.checked);
                        }}
                      />
                      <label
                        className="switch"
                        htmlFor={`togglee${module.id}`}
                      ></label>
                    </div>
                  </h2>
                  <div
                    id={`collapse${module.id}`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`heading${module.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {module.permission.map((perm) => (
                        <div
                          className="accodion-body-inn"
                          key={perm.permission_id}
                        >
                          <div className="accrd-heading">
                            {perm.permission_type}
                          </div>
                          <div className="checkbox-onoff">
                            <input
                              type="checkbox"
                              id={`toggle${perm.permission_id}`}
                              checked={
                                checkedPermissions[module.id]?.[
                                  perm.permission_id
                                ] || false
                              }
                              onChange={(e) =>
                                toggleEditPermission(
                                  module.id,
                                  perm.permission_id,
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="switch"
                              htmlFor={`toggle${perm.permission_id}`}
                            ></label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="deo-buttons_s">
            {permission?.delete === 1 ? (
              <button
                type="button"
                onClick={() => deleteButton()}
                className="popup-btn"
              >
                {" "}
                Delete role
              </button>
            ) : (
              ""
            )}
            <button type="submit" className="popup-btn rolesave_btn">
              {" "}
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default EditRolePermission;
