import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addSubUser } from "../../../../apis/generalSetting/SubUsers";
import { handleNavigation } from "../../../../Utils/Utils";
import { get_all_year_data } from "../../../api/common";
import { get_active_class_grades,get_active_class_terms,get_class_courses,class_add} from "../../../api/Class";
const AddClass = () => {
  const [yearData, setYearData] = useState([]);
  const [gradData, setGradeData] = useState([]);
  const [termData, setTermData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const branchid = localStorage.getItem("b_id");
  const branchName = JSON.parse(localStorage.getItem("b_name"));
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm(({
    mode: "onChange"
  }))
  const grade_id=watch('grade');
  const term_id=watch('term');
  const year_id=watch('year');
  console.log(courseData,);
  
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const YearList = await get_all_year_data();
      const gradeList = await get_active_class_grades();
      const termList = await get_active_class_terms(year_id);
      setYearData(YearList?.data);
      setGradeData(gradeList?.data);
      setTermData(termList?.data);
    };
    fetchData();
  }, [branchid,year_id]);
  useEffect(() => {
    const fetchCourse= async (grade_id,term_id) =>{
        const courseList=await get_class_courses(grade_id,term_id);
        setCourseData(courseList?.data)
    }
    if(grade_id && term_id){
        fetchCourse(grade_id,term_id);
    }
  }, [grade_id,term_id]);
  const onSubmit = async (data) => {
    data.branch_id = branchid
    let result = await class_add(data);
    if (result.status) {
      return navigate("/branch/class-mangament/all");
    }
    // reset();
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-users">
              <h4 className="modal-title">Add Class</h4>
              <Link
                className="edit-btn"
                to={`/branch/class-mangament/all`}
                onClick={(e) => {
                  handleNavigation(e, isDirty);
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <div className="col-md-12">
            <div className="permission-sec-n">
              {/* <h4 className="modal-title">Permissions</h4> */}
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Class Name *</label>
                          <input
                            type="text"
                            placeholder="Enter Class Name"
                            className="form-control"
                            {...register("class_name", { required: true })}
                          />
                          {errors.class_name &&
                            errors.class_name.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year *</label>
                          <select
                            className="form-select"
                            {...register("year", { required: "true" })}
                          >
                            {yearData?.length > 0
                              ? [
                                  <option value="">Choose Year</option>,
                                  yearData?.map((elem) => {
                                    return (
                                      <option value={elem?.current_year}>
                                        {elem?.current_year}
                                      </option>
                                    );
                                  }),
                                ]
                              : [<option value="">Years not available</option>]}
                          </select>
                          {errors.year && errors.year.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Grade *</label>
                          <select
                            className="form-select"
                            {...register("grade", { required: "true" })}
                          >
                            {gradData?.length > 0
                              ? [
                                  <option value="">Choose Grade</option>,
                                  gradData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        Year {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [
                                  <option value="">
                                    Grades not available
                                  </option>,
                                ]}
                          </select>
                          {errors.grade &&
                            errors.grade.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Term *</label>
                          <select
                            className="form-select"
                            {...register("term", { required: "true" })}
                          >
                            {termData?.length > 0
                              ? [
                                  <option value="">Choose Term</option>,
                                  termData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [<option value="">Terms not available</option>]}
                          </select>
                          {errors.term &&
                            errors.term.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Course *</label>
                          <select
                            className="form-select"
                            {...register("course_id", { required: "true" })}
                          >
                            {courseData?.length > 0
                              ? [
                                  <option value="">Choose Course</option>,
                                  courseData?.map((elem) => {
                                    return (
                                      <option value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    );
                                  }),
                                ]
                              : [
                                  <option value="">
                                    Course not available
                                  </option>,
                                ]}
                          </select>
                          {errors.course_id &&
                            errors.course_id.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Class Timing *</label>
                          <select
                            className="form-select"
                            {...register("class_timing", { required: "true" })}
                          >
                            <option value="morning">Morning</option>
                            <option value="afternoon">Afternoon</option>
                          </select>
                          {errors.class_timing && errors.class_timing.type === "required" && (
                            <span className="validation-error">
                              This is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            name="descriptions"
                            placeholder="ENTER DESCRIPTION"
                            rows="3"
                            {...register("class_description")}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Instructor Name</label>
                          <input
                            type="text"
                            placeholder="Enter Instructor Name "
                            className="form-control"
                            {...register("instructor_name")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddClass;
