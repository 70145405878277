import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader.jsx";
import {
  subject_all,
  subject_destroy,
} from "../../../../apis/ClassManagement/Subjects.jsx";
import CustomTable from "../../CustomTable.jsx";
import { getUserPermission } from "../../../../Utils/Utils.js";

const AllSubjects = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission('Subjects');
  const UserID=JSON.parse(localStorage.getItem('userData'))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await subject_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, subject,delete_status } = elem;
        return {
          no: `#${index + 1}`,
          subject: subject,
          status: delete_status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/general-setting/subject/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button className="dlt-btn" onClick={() => destroy(id,delete_status == 1 ? 0 : 1,subject)}>
                  {" "}
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id,status,name) => {
      const data = { delete_status: status,title:name,deleted_by:UserID };
      const result = window.confirm(
        `${
            status == 1
            ? "Are you sure you want to active  this subject ?"
            : "Are you sure you want to  inactive  this subject ? "
        }`
      );
      if (result) {
        await subject_destroy(id,data);
      }
      get_data();
    };
    get_data();
  }, []);


  return (
    <div className="right-nav" id="dash-event">
       {showResults ? (
        <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Subject List</h4>
        </div>
        <div className="col-md-8">
          {permissions?.add === 1 ? (
            <Link
              className="ui button float-end"
              to="/admin/general-setting/subject/add"
            >
              Add Subject
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
        <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default AllSubjects;
