import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get_roles } from "../../../../apis/generalSetting/RolePermission";
import AddRolePermission from "./Add";
import EditRolePermission from "./Edit";
import { getUserPermission } from "../../../../Utils/Utils";
import DataLoader from "../../../../Utils/DataLoader";

const AllRolePermission = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const permissions = getUserPermission("RolePermission");
  useEffect(() => {
    async function get_data() {
      const result = await get_roles();
      setData(result?.data);
      setShowResults(true);
    }
    get_data();
  }, [editModal, isModalOpen]);
  const navigateUser = () => {
    navigate("/admin/administrator-setting/sub-users/all");
  };
  return (
    <>
      <div className="right-nav" id="dash-event">
        {showResults ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <h4 className="modal-title">Roles and Permissions</h4>
              </div>
              <div className="col-md-8">
                {permissions?.add === 1 ? (
                  <button
                    className="ui button float-end"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <span className="me-2">
                      <i class="fa-solid fa-plus"></i>{" "}
                    </span>{" "}
                    New role
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="accunts-global-sec">
              <div className="container">
                <div className="accountsblobal-inner">
                  {data &&
                    data?.map((ele) => {
                      return (
                        <a
                          className="btn btn-primary account-btn"
                          onClick={() => {
                            if (permissions?.edit === 1) {
                              setEditModal(true);
                              setId(ele?.role_id);
                            }
                          }}
                          role="button"
                        >
                          <div className="accoundbtn-cnts">
                            <div className="accnt-top-cnts">
                              <div className="btn-heading">{ele?.role}</div>
                              <div className="blobal-txt">{ele?.branch}</div>
                            </div>
                            <p>{ele?.description}</p>
                          </div>
                          <div
                            class="profiles_s"
                            onClick={() => navigateUser()}
                          >
                            {ele?.first_names?.length > 0 ? (
                              ele?.first_names
                                ?.slice(0, 8)
                                ?.map((firstName, index) => (
                                  <div
                                    key={index}
                                    className="profiles_s-img profiles_s-img_text profile_s-txt prf_margin"
                                  >
                                    {firstName.slice(0, 2).toUpperCase()}
                                  </div>
                                ))
                            ) : (
                              <>
                                <p className="text-dark">No user assign</p>
                              </>
                            )}
                            {ele?.first_names?.length > 0 ? (
                              <div className="profiles_s-img profile_s-txt prf_margin">
                                +{ele?.total_count}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <DataLoader />
        )}
      </div>
      <AddRolePermission
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <EditRolePermission
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        id={id}
      />
    </>
  );
};
export default AllRolePermission;
