import React from 'react';
import { Link, useNavigate,useParams } from 'react-router-dom';
import { useState, useEffect,useRef } from 'react';
import { get_student_list_attendance, get_student_attendance_date } from '../../../apis/Attendance';
const ViewAttendance = () => {
    const [students, setStudent] = useState([]);
    const [selectedVal, setSelectedVal] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().slice(0, 10));
    const navigate = useNavigate();
    // const handleCheckbox = async (user_id, e) => {
    //     const formData = { 'date': currentDate, 'u_id': user_id, 'presence': e.target.checked };
    //     user_id && e.target.checked ? setSelectedVal(prev => ([...prev, { id: "", user_id, presence: true }])) :
    //     setSelectedVal(prev => (prev.filter(entry => entry.user_id !== user_id)))
    //     // let result = await submit_attendance(formData);
    // };
    // const user = JSON.parse(localStorage.getItem('userData'));
    const id = useParams();
    useEffect(() => {
        async function get_data() {
            const student = await get_student_list_attendance(id?.id);
            const studentData = await get_student_attendance_date(currentDate);
            setStudent(student?.data);
            setSelectedVal(studentData.data);
        }
        get_data();
    }, [currentDate]);
    const inputRef = useRef();
    useEffect(() => {
        const dtToday = new Date();
        const month = (dtToday.getMonth() + 1).toString().padStart(2, '0');
        const day = dtToday.getDate().toString().padStart(2, '0');
        const maxDate = `${dtToday.getFullYear()}-${month}-${day}`;
    
        // alert(maxDate);
        inputRef.current && (inputRef.current.max = maxDate);
      }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Attendance</h4>
                    <Link className='edit-btn' to={`/admin/users`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-08 text-center d-flex mb-4">
                            <input type="date" className="form-control" max="9999-12-31" value={currentDate} id="currentDate" onChange={(e) => setCurrentDate(e.target.value)} ref={inputRef} />&nbsp;&nbsp;
                            {/* <input type="button" className="btn btn-success" value="Apply" onClick={handleButton} /> */}
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#S No.</th>
                                    <th>Student Name</th>
                                    <th>Presence</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {
                                    students && students?.map((elem, index) => {
                                        return (
                                            <tr key={elem?.id}>
                                                <th scope="row">#{index + 1}</th>
                                                <td>{`${elem?.first_name}`}</td>
                                                <td>
                                                    <ul className="list-inline m-0">
                                                        <li className="list-inline-item active-row form-check">
                                                           {elem?.presence==='true'
                                                           ? <input class="form-check-input"
                                                           type="checkbox"
                                                           name="status"
                                                         
                                                           // onChange={(e) => {handleCheckbox(elem?.id, e)}}
                                                           checked
                                                           disabled
                                                       />:
                                                        <input class="form-check-input"
                                                                type="checkbox"
                                                                name="status"
                                                              
                                                                // onChange={(e) => {handleCheckbox(elem?.id, e)}}
                                                                // checked
                                                                disabled
                                                            />}
                                                           
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAttendance;
