import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { grade_update, grade_edit, grades_all_data } from "../../../apis/Grade";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const EditGrade = ({
  isModalOpen,
  setIsModalOpen,
  duplicateItemId,
  status,
  setStatus,
}) => {
  const [checkValidation, setCheckValidation] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const regexPattern = /^(0?[0-9]|1[0-2])$/;

  const checkregex = (name) => {
    setCheckValidation(regexPattern.test(name));
  };

  // Get grade data
  useEffect(() => {
    const get_data = async () => {
      const result = await grade_edit(duplicateItemId);
      if (result?.data?.length > 0) {
        const { name, status } = result?.data[0];
        setValue("name", name);
        setValue("status", status);
      }
    };
    if (isModalOpen) {
      get_data();
    }
  }, [isModalOpen, duplicateItemId]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (!checkValidation) {
      console.log("not valid");
    } else {
      let result = await grade_update(duplicateItemId, data);
      if (result?.status === true) {
        setIsModalOpen(false);
        setStatus("");
        // navigate('/admin/general-setting/grades/all');
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Edit Grade"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-header">
        <h1 className="modal-title fs-5">Edit Grade</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(false)}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Grade Name *</label>
              <div class="year-input">
                <span>Year</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Grade Name"
                  {...register("name", { required: true })}
                  onChange={(e) => checkregex(e.target.value)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              {errors.name && (
                <span className="validation-error">This is required</span>
              )}
              {!checkValidation && (
                <span className="validation-error">
                  The grade name should be a number between 0 and 12.
                </span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Status</label>
              <div className="form-check form-check-inline mt-3">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("status")}
                  id="inlineRadio1"
                  value="1"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline mt-3">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("status")}
                  id="inlineRadio2"
                  value="0"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Inactive
                </label>
              </div>
              {errors.status && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!checkValidation || isSubmitting}
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditGrade;
