import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { get_terms_data, term_destroy } from '../../../apis/Common'
import CustomTable from '../CustomTable';
import EditTerms from './Edit'
import { getUserPermission } from '../../../Utils/Utils';
const AllTerms = () => {
    const [showResults, setShowResults] = useState(false);
    const [term, setTerm] = useState([]);
    const [status, setStatus] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    const permissions = getUserPermission('Terms');
    
     const columns1 = [
        {
            name: 'No.',
            selector: 'no',
            sortable: false,
        },
        {
            name: 'Term',
            selector: 'name',
            sortable: false,
        },
        {
            name: 'State',
            selector: 'state',
            sortable: false,
        },
        {
            name: 'Start Date',
            selector: 'start_date',
            sortable: false,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
        },
       ( permissions?.edit === 1 || permissions?.delete === 1 ? {
            name: 'Action',
            selector: 'action',
            sortable: false,
        }:'')
    ];
    function formatDate(dateString) {
        if (!dateString) return '-'; 
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return `${day}-${month}-${year}`;
    }

    useEffect(() => {

        async function get_data() {
            const result = await get_terms_data();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name,state,start_date, status } = elem;

                return {
                    no: `#${index + 1}`,
                    name:name,
                    state:state,
                    start_date:formatDate(start_date),
                   // end_date:formatDate(end_date),
                    status: status == '0' ? 'Inactive' : 'active',
                    action: <div data-tag="allowRowEvents">
                        {
                             permissions?.edit === 1?
                             <button onClick={() => {
                                 setDuplicateItemId(id); setIsModalOpen(true); setStatus('term')
                             }}
                                 className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="fa fa-pencil" aria-hidden="true"></i></button>
                      :''  }
                      {
                         permissions?.delete === 1?
                         <button className="dlt-btn" onClick={() => destroy(id)}>     <i class="fa fa-trash" aria-hidden="true"></i></button>:''
                      }
                    </div>
                };
            });
            setTerm(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                term_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

   
    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="right-nav" id="dash-event">
                {
                        showResults ?
                        <>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h4 className='modal-title'>Terms List</h4>
                        </div>
                        <div className='col-md-8'>
                            {
                                permissions?.add === 1?
                            <Link className='ui button float-end' to="/admin/general-setting/term/add">Add Term
                            </Link>:''
                            }
                        </div>
                    </div>
                  
                            <CustomTable
                                columns={columns1}
                                data={term}
                            />
                        </>
                            :
                            <DataLoader />
                    }


                </div>
            </div>
            <EditTerms duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </div>

    )
}
export default AllTerms;



