import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import CustomTable from "../../CustomTable";
import {
  get_question,
  question_destroy,
  question_edit,
} from "../../../../apis/testManagement/Question";
import { getUserPermission } from "../../../../Utils/Utils";

const AllQuestionaire = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const permissions = getUserPermission("Questionaire");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "grade",
      sortable: true,
    },
    {
      name: "Questionnaire Name",
      selector: "question_name",
      sortable: true,
    },
    {
      name: "Duration",
      selector: "duration",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
          sortable: true,
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await get_question();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, time, test_type, question_name, name, delete_status } =
          elem;
        const questionID = result?.data?.id;
        console.log("questionID", questionID);
        return {
          no: `#${index + 1}`,
          grade: name == 0 ? `year ${name}(kindy)` : `year ${name}`,
          question_name: question_name,
          duration: `${time} minutes`,
          test_type: test_type,
          status: delete_status == "1" ? "ACTIVE" : "INACTIVE",
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.view === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/admin/test-management/questionaire-allDetail/${id}`}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.edit === 1 ? (
                <Link
                  className="edit-btn"
                  onClick={() => navigateToEditPage(id)}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() =>
                    destroy(id, delete_status == "1" ? 0 : 1, question_name)
                  }
                >
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active  this questionnaire  ?"
            : "Are you sure you want to  inactive  this questionnaire  ? "
        }`
      );
      if (result) {
        await question_destroy(id, data);
      }
      get_data();
    };
    const navigateToEditPage = async (id) => {
      const result = await question_edit(id);
      let data = result.data;
      localStorage.setItem("questionData", JSON.stringify(data));
      console.log("result", data);

      navigate(
        `/admin/test-management/questionaire-add/add/${data.grade_id}/${data.subject_id}`
      );
    };
    get_data();
  }, []);

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Questionnaire List</h4>
                    </div>
                    <div className="col-md-8">
                      <Link
                        className="ui button float-end"
                        to="/admin/test-management/questionaire-add"
                      >
                        Add Questionnaire
                      </Link>
                      {/* <Link className='ui button float-end' to="/admin/test-management/questionaire-allDetail">View
                                    </Link> */}
                    </div>
                    <div className=""></div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllQuestionaire;
