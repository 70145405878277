import React, { useEffect, useState } from "react";
import { subject_all } from "../../../../apis/ClassManagement/Subjects.jsx";
const EditHomeWork = ({
  subjectIds,
  subjectId,
  setHomeWork,
  homeWork,
  selectedSubjectId,
  singleTypeFormdata,
  gradeId,
  skillData,
}) => {
  const [forms, setForms] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const payload = {
  //         questionnaire_type: "Course",
  //         grade_id: gradeId,
  //       };
  //       if (subjectId) {
  //         const subjectData = await subject_all(subjectId);
  //         setExistingSubject(subjectData?.data || {});
  //         const existingForms = subjectData?.data?.units?.map((unit) => ({
  //           unit_id: unit.unit_id,
  //           week: unit.week,
  //           q_id: unit.q_id,
  //           u_image: unit.u_image,
  //           u_audio: unit.u_audio,
  //           description: unit.description,
  //           u_document: unit.u_document,
  //           link: unit.link,
  //           text_book_id: unit.text_book_id,
  //         }));
  //         setForms(existingForms);
  //         setHomeWork(existingForms); // Set existing forms to the parent component
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [subjectId, setHomeWork, subjectIds]);
  // useEffect(() => {
  //   if (selectedSubjectId && singleTypeFormdata) {
  //     let fetchData = async () => {
  //       const payload = {
  //         questionnaire_type: "Course",
  //         grade_id: gradeId,
  //       };
  //       const questionList = await get_question_by_type(payload);
  //       // const textBookList = await get_text_books();
  //       const textBookList = await get_text_books_subject_id(selectedSubjectId);
  //       setQuestion(questionList?.data || []);
  //       setTextBook(textBookList?.data || []);
  //       let textbookResult = {};
  //       let questionResult = {};

  //       singleTypeFormdata[`subject_id_${selectedSubjectId}`]?.forEach(
  //         (item) => {
  //           if (item.textbook_id !== null) {
  //             textbookResult[item.unit_id] = item.textbook_id;
  //             // Use unit_id as the key and textbook_id as the value
  //           }
  //           if (item.q_id !== null) {
  //             questionResult[item.unit_id] = item.q_id;
  //             // Use unit_id as the key and textbook_id as the value
  //           }
  //         }
  //       );
  //       setSlectedOptionIds({
  //         textBook: textbookResult,
  //         questions: questionResult,
  //       });
  //       setForms(singleTypeFormdata[`subject_id_${selectedSubjectId}`]);
  //     };
  //     fetchData();
  //     setForms(singleTypeFormdata[`subject_id_${selectedSubjectId}`]);
  //   }
  // }, [selectedSubjectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (subjectId) {
          const subjectData = await subject_all(subjectId);
          const filteredData = subjectData?.data?.filter(
            (item) => item.subject_id === subjectId
          );
          const existingForms = filteredData.map((unit) => ({
            unit_id: unit.unit_id,
            start_date: unit.start_date,
            homeWork: unit.units_json_data.map((homeWork) => ({
              title: homeWork?.title || "",
              file_path: homeWork?.file_path || "",
              jwp_media: homeWork?.jwp_media || "",
              movie_type: homeWork?.movie_type || "",
            })),
          }));
          setForms(existingForms);
          setHomeWork(existingForms);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [subjectId]);
  useEffect(() => {
    const processEditData = () => {
      if (singleTypeFormdata && selectedSubjectId) {
        const filteredData = singleTypeFormdata.data.filter(
          (unit) => unit.subject_id === selectedSubjectId
        );
        const processedForms = filteredData.map((unit) => ({
          unit_id: unit.unit_id,
          start_date: unit.start_date || "",
          homeWork:
            unit.units_json_data.length > 0
              ? unit.units_json_data.map((homeWork) => ({
                  skill: homeWork.skill || "",
                  title: homeWork.title || "",
                  tstm_guid: homeWork.tstm_guid || "",
                }))
              : skillData?.map(() => ({
                  skill: "",
                  title: "",
                  tstm_guid: "",
                })),
        }));
        setForms(processedForms);
        setHomeWork(processedForms);
      }
    };

    processEditData();
  }, [singleTypeFormdata, skillData, selectedSubjectId]);

  const handlehomeWorkInputChange = (e, unitIndex, movieIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].homeWork[movieIndex] = {
      ...updatedForms[unitIndex].homeWork[movieIndex],
      [name]: value,
    };
    setForms(updatedForms);
    setHomeWork(updatedForms);
  };
  return (
    <div className="accordion" id="accordionExample">
       {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Skill Type</th>
                      <th>Title</th>
                      <th>TSTM GUID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.homeWork.map((homeWork, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select"
                            name="skill"
                            value={homeWork.skill}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="" >
                              Select Type
                            </option>
                            {skillData.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.skill}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={homeWork.title}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="tstm_guid"
                            value={homeWork.tstm_guid}
                            onChange={(e) =>
                              handlehomeWorkInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditHomeWork;
