import React, { useEffect, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { events_add } from "../../../apis/Events";
import { handleNavigation } from "../../../Utils/Utils";
import { state_branch_all } from "../../../apis/Branches";
import { get_states } from "../../../apis/Common";
import { useRef } from "react";
const AddEvent = () => {
  const [show, setShow] = useState(false);
  const [stateID, setStateId] = useState("");
  const [statelist, setStateList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isDirty },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const firstRender = useRef(true);
  const [showEvent, setShowEvent] = useState({
    online: false,
    offline: false,
  });
  const navigate = useNavigate();
  const showField = (e) => {
    const selectedValue = e.target.value;
    selectedValue == "interview" ? setShow(true) : setShow(false);
    setValue("event_type", selectedValue, { shouldValidate: true });
  };
  const onSubmit = async (data) => {
    data.offline_event = data.offline_event === true ? 1 : 0;
    data.online_event = data.online_event === true ? 1 : 0;
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await events_add(data);
    if (result.status) {
      return navigate("/admin/administrator-setting/events");
    }
    setIsSubmitting(false);
  };
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());
  const TodayDate = maxDate.toISOString().split("T")[0];
  useEffect(() => {
    const fetchData = async () => {
      // const branchList = await branch_all();
      const branchList = await state_branch_all(stateID);
      const stateList = await get_states();
      setBranchList(branchList?.data);
      setStateList(stateList?.data);
    };
    fetchData();
  }, [stateID]);
  const offlineChecked = watch("offline_event");
  const onlineChecked = watch("online_event");
  const atLeastOneChecked = offlineChecked || onlineChecked;
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      trigger(["offline_event", "online_event"]);
    }
  }, [offlineChecked, onlineChecked, trigger]);
  useEffect(() => {
    setShowEvent({
      online: onlineChecked,
      offline: offlineChecked,
    });
  }, [onlineChecked, offlineChecked]);
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">New Event</h4>
          <Link
            className="edit-btn"
            to={`/admin/administrator-setting/events`}
            onClick={(e) => {
              handleNavigation(e, isDirty);
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Event Type *</label>
                  <select
                    className="form-select"
                    name="event"
                    {...register("event_type", { required: true })}
                    onChange={(e) => {
                      showField(e);
                    }}
                  >
                    <option value="">Choose Event</option>
                    <option value="seminar">Seminar</option>
                    <option value="interview">Interview</option>
                  </select>
                  {errors.event_type &&
                    errors.event_type.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Title *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    placeholder="Enter Title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && errors.title.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Date *</label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    max="9999-12-31"
                    min={TodayDate}
                    {...register("date", { required: true })}
                  />
                  {errors.date && errors.date.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Time Start *</label>
                  <input
                    type="time"
                    className="form-control"
                    name="time_start"
                    {...register("time_start", { required: true })}
                  />
                  {errors.time_start &&
                    errors.time_start.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Time End *</label>
                  <input
                    type="time"
                    className="form-control"
                    name="time_end"
                    {...register("time_end", { required: true })}
                  />
                  {errors.time_end && errors.time_end.type === "required" && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              {show ? (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Interview Time Limit</label>
                    <select
                      className="form-control"
                      name="interview_time_limit"
                      {...register("interview_time_limit")}
                    >
                      <option value="">N/A</option>
                      <option value="15">15 minutes</option>
                      <option value="20">20 minutes</option>
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="mt-3" />
                <div className="form-group">
                  <label>Venue Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="venue_name"
                    placeholder="Enter Venue Name"
                    {...register("venue_name", { required: true })}
                  />
                  {errors.venue_name &&
                    errors.venue_name.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>State *</label>
                  <select
                    className="form-select form-control"
                    {...register("state", { required: "This is required" })}
                    onChange={(e) => setStateId(e.target.value)}
                  >
                    {statelist?.length > 0
                      ? [
                          <option value="">Choose State</option>,
                          statelist?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          }),
                        ]
                      : [<option value="">State not available</option>]}
                  </select>
                  {!stateID && errors.state && (
                    <span className="validation-error">This is required</span>
                  )}
                </div>
              </div>
              <div className={`col-md-6`}>
                <div className="form-group">
                  <label>Branch</label>
                  <select
                    className="form-select form-control"
                    name="grade"
                    {...register("branch")}
                  >
                    {branchlist?.length > 0
                      ? [
                          <option value="">Choose Branch</option>,
                          branchlist?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.branch_name}
                              </option>
                            );
                          }),
                        ]
                      : [
                          <option value="">Branch not available</option>,
                        ]}
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="highlight_event">
                  <div className="form-group">
                    <label>Offline/Online Event</label>
                    <div className="highlight_inner">
                      <div className="checkbox">
                        <label>
                          <Controller
                            name="offline_event"
                            control={control}
                            rules={{
                              validate: () =>
                                atLeastOneChecked ||
                                "Please select at least one event type",
                            }}
                            render={({ field }) => (
                              <input
                                type="checkbox"
                                id="event_offline"
                                {...field}
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                          Offline
                        </label>
                      </div>
                      <div className="checkbox">
                        <label>
                          <Controller
                            name="online_event"
                            control={control}
                            rules={{
                              validate: () =>
                                atLeastOneChecked ||
                                "Please select at least one event type",
                            }}
                            render={({ field }) => (
                              <input
                                type="checkbox"
                                id="event_online"
                                {...field}
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                          Online
                        </label>
                      </div>
                    </div>
                    {!atLeastOneChecked &&
                      (errors.offline_event || errors.online_event) && (
                        <span className="validation-error">
                          Please select at least one event type
                        </span>
                      )}
                  </div>
                </div>
              </div>
              {showEvent?.offline === true ? (
                <>
                  {" "}
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>(Offline) Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="offine_address"
                        placeholder="Enter Offline Address"
                        {...register("offine_address", { required: true })}
                      />
                      {errors.offine_address &&
                        errors.offine_address.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>(Offline) Seats *</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Offline Seats"
                        name="offine_seats"
                        {...register("offine_seats", { required: true })}
                      />
                      {errors.offine_seats &&
                        errors.offine_seats.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {showEvent?.online === true ? (
                <>
                  <div className="col-md-9">
                    <div className="form-group">
                      <label>(Online) URL *</label>
                      <input
                        type="text"
                        placeholder="Enter Url"
                        className="form-control"
                        name="online_url"
                        {...register("online_url", { required: true })}
                      />
                      {errors.online_url &&
                        errors.online_url.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>(Online) Seats *</label>
                      <input
                        type="number"
                        placeholder="Enter Online Seats"
                        className="form-control"
                        name="online_seats"
                        {...register("online_seats", { required: true })}
                      />
                      {errors.online_seats &&
                        errors.online_seats.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Description"
                    name="description"
                    rows="3"
                    {...register("description")}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <hr className="mt-3" />
                <div className="form-group">
                  <label>
                    <b>Booking Date</b>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Start *</label>
                  <input
                    type="date"
                    className="form-control"
                    name="booking_date_start"
                    max="9999-12-31"
                    {...register("booking_date_start", { required: true })}
                    min={TodayDate}
                  />
                  {errors.booking_date_start &&
                    errors.booking_date_start.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>End *</label>
                  <input
                    type="date"
                    className="form-control"
                    name="booking_date_end"
                    max="9999-12-31"
                    {...register("booking_date_end", { required: true })}
                    min={TodayDate}
                  />
                  {errors.booking_date_end &&
                    errors.booking_date_end.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                </div>
              </div>
              <br />
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    <b>Break (if applicable)</b>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Start</label>
                  <input
                    type="time"
                    className="form-control"
                    name="break_start"
                    {...register("break_start")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>End</label>
                  <input
                    type="time"
                    className="form-control"
                    name="break_end"
                    {...register("break_end")}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Save Event
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
