import React, { useState, useEffect } from "react";
import DataLoader from "../../../../Utils/DataLoader";
import CustomTable from "../../CustomTable";
import { get_all_evaluation,evaluation_destroy } from "../../../../apis/Course";
import UpdateEvaluation from "./UpdateEvaluationModal";
const AllEvalutation = ({term_id,course_id,CallEvaluationList,year,grade_id}) => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isModalOpen,setIsModalOpen]=useState(false);
  const [evaluationId,setEvaluationId]=useState('')
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: false,
    },
    {
      name: "Test Name",
      selector: "test_title",
      sortable: false,
    },
    {
      name: "Test Type",
      selector: "test_type",
      sortable: false,
    },
    {
      name: "Test Kind",
      selector: "test_kind",
      sortable: false,
    },
    {
      name: "Test no",
      selector: "test_no",
      sortable: false,
    },
    {
      name: "Total Questions",
      selector: "test_total_questions",
      sortable: false,
    },
    {
      name: "Time",
      selector: "test_total_time",
      sortable: false,
    },
    {
        name: "TSTM GUID",
        selector: "tstm_guid",
        sortable: false,
      },
      {
        name: "JWP Media",
        selector: "jwp_media_id",
        sortable: false,
      },
      {
        name: "Expiration Date",
        selector: "expiration_date",
        sortable: false,
      },
    {
      name: "Status",
      selector: "status",
      sortable: false,
    },
    {
      name: "Action",
      selector: "action",
    },
  ];
  const openEditModal=(id)=>{setIsModalOpen(true);setEvaluationId(id)}
  useEffect(() => {
    async function get_data() {
      const result = await get_all_evaluation(term_id,course_id);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          test_title,
          test_type,
          test_kind,
          test_no,
          test_total_questions,
          test_total_time,
          jwp_media_id,
          tstm_guid,
          expiration_date,
          year,
          subject,
          term_name,
          grade,
          status,
        } = elem;
        return {
          no: `#${index + 1}`,
          test_title: test_title,
          test_type:test_type,
          test_kind:test_kind,
          test_no:test_no,
          test_total_questions:test_total_questions,
          test_total_time: test_total_time,
          tstm_guid: tstm_guid,
          jwp_media_id:jwp_media_id,
          expiration_date:expiration_date,
          term_name: term_name,
          test_title: test_title,
          grade: grade,
          year: year,
          subject: subject,
          //name:name,
          status: status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
                    <button
                    type="button"
                  className="dlt-btn"
                  onClick={() => openEditModal(id)}
                >
                  {" "}
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  className="dlt-btn"
                  onClick={() => destroy(id, status == 1 ? 0 : 1)}
                >
                  {" "}
                  {status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
            </div>
          ),
        };
      });
      setGrade(arrayData, setShowResults(true));
    }
    const destroy = async (id, status) => {
      const data = { status: status};
      const result = window.confirm(
        `${
          status == 1
            ? "Are you sure you want to active  this evaluation ?"
            : "Are you sure you want to  inactive  this evaluation ? "
        }`
      );
      if (result) {
        await evaluation_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, [CallEvaluationList,term_id,course_id,isModalOpen]);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
              {showResults ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="modal-title">Evaluation List</h4>
                    </div>
                  </div>
                  <CustomTable columns={columns} data={grade} />
                </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
        <UpdateEvaluation isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} term_id={term_id} course_id={course_id} id={evaluationId} year={year} grade_id={grade_id}/>
      </div>
    </>
  );
};
export default AllEvalutation;
