import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { test_type_list } from "../../../../apis/Common.jsx";
import {
  evaluation_edit,
  evaluation_update,
} from "../../../../apis/Course.jsx";
const UpdateEvaluation = ({
  isModalOpen,
  setIsModalOpen,
  term_id,
  course_id,
  id,
  year,
  grade_id
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [testTypeData, setTestTypeData] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Get evaluation data
  useEffect(() => {
    const get_data = async () => {
      const result = await evaluation_edit(id);
      const testTypeList = await test_type_list(year, grade_id,term_id);
      setTestTypeData(testTypeList?.data);
      const data = result?.data[0];
      if (result?.data?.length > 0) {
        setValue("test_name", data?.test_title);
        setValue("status", data?.status.toString());
        setValue("test_type", data?.test_type);
        setValue("test_kind", data?.test_kind);
        setValue("test_no", data?.test_no);
        setValue("test_total_ques", data?.test_total_questions);
        setValue("test_total_time", data?.test_total_time);
        setValue("test_guid", data?.tstm_guid);
        setValue("jwp_media_id", data?.jwp_media_id);
        setValue("expiration_date", data?.expiration_date);
      }
    };
    if (isModalOpen) {
      get_data();
    }
  }, [isModalOpen, id]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    let result = await evaluation_update(id, data);
    if (result?.status === true) {
      setIsModalOpen(false);
      // navigate('/admin/general-setting/grades/all');
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Edit Grade"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-header">
        <h1 className="modal-title fs-5">Edit Evaluation</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(false)}
          className="btn-close"
          aria-label="Close"
        ></button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <span>Test name</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("test_name", { required: true })}
              />
              {errors.test_name && errors.test_name.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Test type</span>
              <select
                className="form-select"
                name="test_type"
                {...register("test_type", { required: true })}
              >
                <option value="" disabled>
                  Select Test Type
                </option>
                {testTypeData?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type?.test_title}
                  </option>
                ))}
              </select>
              {errors.test_type && errors.test_type.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Test Kind</span>
              <select
                className="form-select"
                name="test_kind"
                {...register("test_kind", { required: true })}
              >
                <option value="objective">Objective</option>
                <option value="selective">Selective</option>
              </select>
              {errors.test_kind && errors.test_kind.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Test No</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("test_no", { required: true })}
              />
              {errors.test_no && errors.test_no.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Test total questions</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("test_total_ques", { required: true })}
              />
              {errors.test_total_ques &&
                errors.test_total_ques.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Test total time</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("test_total_time", { required: true })}
              />
              {errors.test_total_time &&
                errors.test_total_time.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>TSTM GUID</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("test_guid", { required: true })}
              />
              {errors.test_guid && errors.test_guid.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>JWP Media Id</span>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("jwp_media_id", { required: true })}
              />
              {errors.jwp_media_id &&
                errors.jwp_media_id.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Expiration date</span>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Text Book Name"
                {...register("expiration_date", { required: true })}
              />
              {errors.expiration_date &&
                errors.expiration_date.type === "required" && (
                  <span className="validation-error">This is required</span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Status</label>
              <div className="form-check form-check-inline mt-3">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("status")}
                  id="inlineRadio1"
                  value="1"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline mt-3">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("status")}
                  id="inlineRadio2"
                  value="0"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Inactive
                </label>
              </div>
              {errors.status && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateEvaluation;
