import React, { useEffect, useState } from "react";
import { subject_all } from "../../../../apis/ClassManagement/Subjects.jsx";

const EditSelfStudy = ({subjectId,setSelfStudy,selectedSubjectId,singleTypeFormdata,movieTypes,}) => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (subjectId) {
          const subjectData = await subject_all(subjectId);
          const filteredData = subjectData?.data?.filter(
            (item) => item.subject_id === subjectId
          );
          const existingForms = filteredData.map((unit) => ({
            unit_id: unit.unit_id,
            start_date: unit.start_date,
            selfDir: unit.units_json_data.map((lecture) => ({
              title: lecture.title || "",
              file_path: lecture.file_path || "",
              jwp_media: lecture.jwp_media || "",
              movie_type: lecture.movie_type || "",
            })),
          }));
          setForms(existingForms);
          setSelfStudy(existingForms);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [subjectId]);
  useEffect(() => {
    const processEditData = () => {
      if (singleTypeFormdata && selectedSubjectId) {
        const filteredData = singleTypeFormdata.data.filter(
          (unit) => unit.subject_id === selectedSubjectId
        );
        const processedForms = filteredData.map((unit) => ({
          unit_id: unit.unit_id,
          start_date: unit.start_date || "",
          selfDir:
            unit.units_json_data.length > 0
              ? unit.units_json_data.map((selfDirct) => ({
                  movie_type: selfDirct.movie_type || "",
                  title: selfDirct.title || "",
                  file_path: selfDirct.file_path || "",
                  jwp_media: selfDirct.jwp_media || "",
                }))
              : movieTypes?.map((movieType) => ({
                  movie_type:"",
                  title: "",
                  file_path: "",
                  jwp_media: "",
                })),
        }));
        setForms(processedForms);
        setSelfStudy(processedForms);
      }
    };

    processEditData();
  }, [singleTypeFormdata, movieTypes, selectedSubjectId]);
  const handleSelfStudyInputChange = (e, unitIndex, movieIndex) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[unitIndex].selfDir[movieIndex] = {
      ...updatedForms[unitIndex].selfDir[movieIndex],
      [name]: value,
    };
    setForms(updatedForms);
    setSelfStudy(updatedForms);
  };
  return (
    <div className="accordion" id="accordionExample">
      {forms.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={form.unit_id} />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Movie Type</th>
                      <th>Title</th>
                      <th>File Path</th>
                      <th>JWP Media</th>
                    </tr>
                  </thead>
                  <tbody>
                    {form.selfDir.map((selfDir, movieIndex) => (
                      <tr key={`${form.unit_id}-${movieIndex}`}>
                        <td className="text-center">{movieIndex + 1}</td>
                        <td>
                          <select
                            className="form-select"
                            name="movie_type"
                            value={selfDir.movie_type}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          >
                            <option value="" >
                              Select Type
                            </option>
                            {movieTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.file_type}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={selfDir.title}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="file_path"
                            value={selfDir.file_path}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="jwp_media"
                            value={selfDir.jwp_media}
                            onChange={(e) =>
                              handleSelfStudyInputChange(e, i, movieIndex)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditSelfStudy;
