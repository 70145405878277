import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';

const CustomTable = ({ columns, data }) => {
    const [searchData, setSeacrhData] = useState('');
    const [filterData, setFilterData] = useState([]);

    const onSearch = (value) => {
        setSeacrhData(value);
        const searchRes = data.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilterData(searchRes);
    };
    const customStyles = {
        table: {
          style: {
            tableLayout: 'fixed',
          },
        },
        headRow: {
          style: {
            backgroundColor: '#f1f1f1',
            color: '#333',
            fontSize: '16px',
          },
        },
        headCells: {
          style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '10px',
            paddingBottom: '10px',
            fontWeight: 'bold',
          },
        },
        rows: {
          style: {
            minHeight: '40px', 
            paddingTop: '0px',
            paddingBottom: '0px',
            backgroundColor: '#fff',
            '&:nth-child(odd)': {
              backgroundColor: '#f9f9f9',
            },
          },
        },
        cells: {
          style: {
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        },
        tableBody: {
          style: {
            maxHeight: '400px', 
            overflowY: 'auto', 
            display: 'block', 
          },
        },
      };
    return (
        <>
            <div className='searc-bar'>
                <input type="search" className="form-control me-2 float-end" placeholder='Search here...' onChange={(e) => onSearch(e.target.value)} />
            </div>
            <DataTable
                noHeader
                columns={columns}
                data={searchData ? filterData : data}
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                paginationPerPage={30}
                highlightOnHover
                customStyles={customStyles}
            />
        </>
    )
}

export default CustomTable;
