import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { useForm ,Controller} from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { member_edit,member_update,} from "../../../apis/studentManagement/StudentManagement";
import { branch_all, course_type_all, state_branch_all } from "../../../apis/Branches";
import { grade_all } from "../../../apis/Grade";
import { get_course_terms, get_days,get_states,get_terms,get_class_by_branch } from "../../../apis/Common";
import DataLoader from "../../../Utils/DataLoader";
import {select_course_all_by_gradeID } from "../../../apis/Course.jsx";
import Select from 'react-select';
import { handleNavigation } from "../../../Utils/Utils.js";
const EditMember = () => {
  const [course, setCourse] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [branchlist, setBranchList] = useState([]);
  const [statelist, setStateList] = useState([]);
  const [coursetypeList, setCoursetypeList] = useState([]);
  const [days, setDays] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termData, setTermData] = useState([]);
  const [gradeID,setGradeId]=useState('');
  const [stateID, setStateId] = useState("");
  const [branchID, setBranchID] = useState("");
  const [courseID,setCourseID]=useState('');
  const [classData,setClassData]=useState([]);
  // const [countries, setCountries] = useState([]);

//mulitle course 
const [selectedCourse,setSelectedCourse]=useState([]);
const handleSubChange = (selectedSubOptions) => {
  setSelectedCourse(selectedSubOptions);
};
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors,isDirty },
    watch,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const password = useRef({});
  const classroom_gmail = useRef({});
  const p_email = useRef({});
  const id = useParams();
  const branch_id=watch('branch');
 const [minDate, setMinDate] = useState('');
const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const today = new Date();
   const maxDateCalc = new Date(today.getFullYear() - 0, today.getMonth(), today.getDate());
     const minDateCalc = new Date(today.getFullYear() -20, 0, 2); 
    setMaxDate(maxDateCalc.toISOString().split('T')[0]);
    setMinDate(minDateCalc.toISOString().split('T')[0]);
  }, []);
  password.current = watch("password", "");
  classroom_gmail.current = watch("classroom_gmail", "");
  p_email.current = watch("p_email", "");

  const onSubmit = async (data) => {
    if (isSubmitting) return; 
    setIsSubmitting(true);
    console.log("data", data);
    data.course=selectedCourse;
    data.pc_email = "";
    let result = await member_update(id, data);
    if (result.status) {
      return navigate("/admin/students/members");
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    const fetchEditMember = async () => {
      const res = await member_edit(id);
      return res?.data;
    };
  
    const fetchDropdownData = async () => {
     // const branchList = await branch_all();
      const courseTypeList = await course_type_all();
      const gradeList = await grade_all();
      const days = await get_days();
     // const terms=await get_terms();
      const terms=await get_terms();
      return {
       // branches: branchList?.data,
        courseTypes: courseTypeList?.data,
        grades: gradeList?.data,
        days: days?.data,
        terms:terms?.data
      };
    };
  
    const fetchData = async () => {
      const memberData = await fetchEditMember();
      const dropdownData = await fetchDropdownData();
  
      if (memberData) {
        setShowResults(true);
        const classData=await get_class_by_branch(memberData?.branch_id);
        setClassData(classData?.data)
        setValue("first_name", memberData.first_name);
        setValue("last_name", memberData.last_name);
        setValue("gender", memberData.gender);
        const dateComponents = memberData.date_of_birth?.split("-");
        const year = parseInt(dateComponents[0]);
        const month = parseInt(dateComponents[1]) - 1; 
        const day = parseInt(dateComponents[2]);
        const formattedDate = new Date(Date.UTC(year, month, day)).toISOString().substring(0, 10);
        setValue("date_of_birth", formattedDate);
        setValue("school", memberData.school);
        setValue("address", memberData.address);
        setValue("studentID", `CSPRO00${memberData.id}`);
        setValue("classroom_gmail", memberData.classroom_gmail);
        setValue("cs_education", memberData.cs_education);
        setSelectedCourse(memberData?.course)
       // setValue("course", memberData.course);
       // console.log(memberData.course)
        setShow(memberData.cs_education === "1");
            setGradeId(memberData?.grade)
            setStateId(memberData?.state)
            setBranchID(memberData?.branch)
        // Set all the rest of the member data
        const fieldsToSet = [
          "grade", "branch", "subject_id", "day", "type",
          "state", "sibling_grade", "course", "student_number",
          "p_first_name", "p_last_name", "p_mobile_phone",
          "mother_mobilenumber", "father_mobilenumber", "address",
          "city", "p_email", "pc_email", "sibling", "email",
          "password", "confirmPassword","term_id","course","class_id"
        ];
  
        fieldsToSet.forEach(field => {
          setValue(field, memberData[field]);
        });
        // if (gradeID) {
        //   const course = await select_course_all_by_gradeID(gradeID);
        //   setSelectedCourse(course?.data);
        // }
        if (dropdownData.grades) {
          setValue("grade",memberData.grade);
          setValue("course",memberData.course);
          setValue("class_id",memberData.class_id);
        }
      }
      //setBranchList(dropdownData.branches);
      setCoursetypeList(dropdownData.courseTypes);
      setGradeList(dropdownData.grades);
      setDays(dropdownData.days);
      setTermData(dropdownData?.terms)
    };
  
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchCoursesByGradeID = async () => {
      if (gradeID) {
        const course = await select_course_all_by_gradeID(gradeID);
        const classData = await get_class_by_branch(branch_id)
        const data=course?.data?.map(item => ({ value: item?.id, label: item.name }))
        setCourse(data);
        setClassData(classData?.data)
      }
    };
  
    fetchCoursesByGradeID();
  }, [gradeID,branch_id]);

  useEffect(() => {
    const fetchStateBranch = async () => {
      if (stateID) {
        const resp = await state_branch_all(stateID);
        const dataa = resp?.data;
        setBranchList(dataa); 
      } else {
        const branchList = await branch_all();
        setBranchList(branchList?.data); 
      }
    };
  
    fetchStateBranch();
  }, [stateID]);

  useEffect(() => {
    const fetchData = async () => {
      const terms = await get_course_terms(courseID);
      const stateList= await get_states();
      setTermData(terms?.data);
      setStateList(stateList?.data);
    };
    fetchData();
  }, [courseID]);
 
  // const maxDate = new Date();
  // maxDate.setFullYear(maxDate.getFullYear() - 8);

  // const maxDateStr = maxDate.toISOString().split("T")[0];
  return (
    <>
    
        <div className="right-nav" id="dash-event">
        {showResults ? (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Account</h4>
              <Link className="edit-btn" to={`/admin/students/members`} onClick={(e) => {handleNavigation(e,isDirty)
                    }}>
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="modal-body">
              <Form
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-md-12">
                  {/* <hr className='mt-3' /> */}
                  <div className="form-group">
                    <label>
                      <b>Step1. Student information</b>
                    </label>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Student Id:</label>
                                        <input type="text" className="form-control" name="venue_name"
                                            {...register('studentID')}
                                            disabled />
                                    </div>
                                </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="Enter First Name"
                        {...register("first_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                      {errors.first_name && errors.first_name && (
                      <span className="validation-error">
                        {errors.first_name.message}
                      </span>
                    )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="Enter Last Name"
                        {...register("last_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                      {errors.last_name && (
                      <span className="validation-error">
                        {errors.last_name.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Gender *</label>
                      <select
                        className="form-select form-control"
                        name="grade"
                        {...register("gender", { required: true })}
                      >
                        <option value="">Choose Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    {errors.gender && errors.gender.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date of Birth *</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date_of_birth"
                        max={maxDate}
                      min={minDate}
                      pattern="dd-mm-yyyy"
                      {...register("date_of_birth", { 
                           required: "Date of birth is required",
                          validate: (value) => {
                            const today = new Date();
                            const birthDate = new Date(value);
                            const age = today.getFullYear() - birthDate.getFullYear();
                            const monthDifference = today.getMonth() - birthDate.getMonth();
                            const dayDifference = today.getDate() - birthDate.getDate();
                            if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                              return age >= 0 && age <= 20 || "Age must be below 20 years";
                            }
                            return age >= 0 && age <= 20 || "Age must be below 20 years";
                          }
                      })}
                        // onKeyDown={(e) => e.preventDefault()}
                      />
                     {errors.date_of_birth && (
                      <span className="validation-error">
                        {errors.date_of_birth.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>School Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="schoole_name"
                        placeholder="Enter School Name"
                        {...register("school", {
                          required: "This is required",
                        })}
                      />
                    {errors.school && (
                      <span className="validation-error">This is required</span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>STUDENT Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        placeholder="Enter Address"
                        {...register("address", {
                          required: "This is required",
                        })}
                      />
                    {errors.address && (
                      <span className="validation-error">This is required</span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>State *</label>
                      <select
                        className="form-select form-control"
                        {...register("state", { required: "This is required" })}
                        onChange={(e)=>setStateId(e.target.value)}
                      >
                        {statelist?.length > 0
                        ? [
                            <option value="">Choose State</option>,
                            statelist?.map((elem) => {
                              return (
                                <option value={elem?.id}>
                                  {elem?.name}
                                </option>
                              );
                            }),
                          ]
                        : [
                            <option value="">State not available</option>,
                          ]}
                      </select>
                      {errors.state && (
                    <span className="validation-error">This is required</span>
                  )}
                    </div>
                  </div>
                  <div className={show ? `col-md-6` : `col-md-6`}>
                    <div className="form-group">
                      <label>Branch</label>
                      <select
                              key={branchlist.length} // Add a key prop and update it when branchlist changes
                              className="form-select form-control"
                              name="grade"
                              {...register("branch")}
                            >
                            
                            {branchlist?.length > 0 ? (
                                            [<option value="">Choose Branch</option>, 
                                            ...branchlist?.map((elem) => {
                                              return (
                                                <option value={elem?.id}>
                                                  {elem?.branch_name}
                                                </option>
                                              );
                                            })]
                                          ) : (
                                            [
                                            // <option value="">Choose Branch</option>
                                            //   ,
                                            <option value="">Branch not available</option>
                                          ]
                                          )}
                            </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Grade *</label>
                      <select
                        className="form-select form-control"
                        name="grade"
                        {...register("grade", {
                          validate: (value) =>
                            value !== "" || "This is required",
                        })}
                        onChange={(e)=>setGradeId(e.target.value)}
                      >
                        <option value="">Choose Grade</option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => (
                            <option key={elem?.id} value={elem?.id}>
                              {elem.name === 0
                                ? `Year ${elem.name} (kindy)`
                                : `Year ${elem.name}`}
                            </option>
                          ))
                        ) : (
                          <option>No result</option>
                        )}
                        <option value="other">Other</option>
                      </select>
                      {gradeID?'':errors.grade && (
                      <span className="validation-error">
                        {errors.grade.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className={show ? `col-md-3` : `col-md-6`}>
                    <div className="form-group">
                      <label>Course Type</label>
                      <select
                        className="form-select form-control"
                        name="grade"
                        {...register("subject_id")}
                      >
                        <option value="">Choose course Type</option>
                        {coursetypeList?.length > 0 ? (
                          coursetypeList &&
                          coursetypeList?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.category_name}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                    </div>
                  </div>
                 
                  {/* <div className={show ? `col-md-4` : `col-md-6`}>
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-select form-control" {...register('type')}>
                                            <option value="">Choose Type</option>
                                            <option value="C1">C1</option>
                                            <option value="C2">C2</option>
                                            <option value="C3">C3</option>
                                        </select>
                                    </div>
                                </div> */}
                  <div className={show ? `col-md-4` : `col-md-6`}>
                    <div className="form-group">
                      <label>class *</label>
                     <select
                      className="form-select form-control"
                      {...register("class_id", {
                        required: true,
                      })}
                    >
                      <option value="">Choose class </option>
                      {classData &&
                        classData?.map((elem) => {
                          return <option value={elem?.id}>{elem?.class_name}</option>;
                        })}
                    </select>
                    {errors.class_id && errors.class_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                    </div>
                  </div>
                  <div className={`col-md-6`}>
                  <div className="form-group">
                    <label>Term *</label>
                    <select
                      className="form-select form-control"
                      {...register("term_id", {
                        required: true,
                      })}
                    >
                      <option value="">Choose Term </option>
                      {termData &&
                        termData?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })}
                    </select>
                    {errors.term_id &&
                      errors.term_id.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className={show ? `col-md-4` : `col-md-6`}>
                    <div className="form-group">
                      <label>Day </label>
                      <select
                        className="form-select form-control"
                        {...register("day")}
                      >
                        <option value="">Choose Day</option>
                        {days &&
                          days?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <hr className="mt-3" />
                    <div className="form-group">
                      <label>
                        <b>Step 2. Parent information</b>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's First Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="venue_name"
                        placeholder="Enter Parent First Name"
                        {...register("p_first_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                      {errors.p_first_name && (
                      <span className="validation-error">
                        {errors.p_first_name.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's Last Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="venue_name"
                        placeholder="Enter Parent Last  Name"
                        {...register("p_last_name", {
                          required: "This is required",
                          validate: (value) =>
                            /^[A-Za-z\s]+$/.test(value) ||
                            "Only letters are allowed",
                        })}
                      />
                       {errors.p_last_name && (
                      <span className="validation-error">
                        {errors.p_last_name.message}
                      </span>
                    )}
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Telephone</label>
                                        <input type="text" className="form-control" name="offine_address"
                                            placeholder='Enter Telephone No.'
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('p_mobile_phone')}
                                        />
                                    </div>
                                </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Primary Contact  *</label>
                      <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        name="offine_seats"
                        {...register("mother_mobilenumber",  {
                          required: "Mobile number  is required",
                          pattern: { value: /^\d{10}$/, message: "mobile number must be 10 digits" },
                          validate: (value) =>
                            /^\d+$/.test(value) || "Only numbers are allowed",
                        })}
                        maxLength={10}
                      />
                      </div>
                      {errors.mother_mobilenumber && (
                      <span className="validation-error">
                        {errors.mother_mobilenumber.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Alternate Contact no</label>
                      <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="000 000 (0000)"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("father_mobilenumber", {
                          pattern: {
                            value: /^\d{10}$/, 
                            message: "Mobile number must be 10 digits",
                          },
                          validate: {
                            isNumeric: (value) => value === "" || /^\d+$/.test(value) || "Mobile number must be numeric",
                          }
                        })}
                        maxLength={10}
                      />
                      </div>
                       {errors.father_mobilenumber && (
                      <span className="validation-error">
                        {errors.father_mobilenumber.message}
                      </span>
                    )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control"
                                            placeholder='Enter Address'
                                            name="online_seats"  {...register('address')} />
                                    </div>
                                </div> */}
                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country (*)</label>
                                        <select className="form-select form-control" {...register('country', { required: true })}>
                                            <option value="">Choose Country</option>
                                            {
                                                countries?.length > 0 ?
                                                    countries && countries?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option>No result</option>
                                            }
                                        </select>
                                        {errors.country && errors.country.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div> */}
                 
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter City"
                        name="description"
                        {...register("city", { required: true })}
                      />
                      {errors.city && errors.city.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Post Code:</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter Post Code'
                                            name="description" {...register('post_code')} />
                                    </div>
                                </div> */}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Parent's Email *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Parent Email"
                        name="p_email"
                        {...register("p_email", {
                          required: true,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                      />
                       {errors.p_email && errors.p_email.type === "required" && (
                      <span className="validation-error">
                        Parent's email is required
                      </span>
                    )}
                    {errors.p_email && (
                      <span className="validation-error">
                        {errors.p_email.message}
                      </span>
                    )}
                    </div>
                  </div>
                  <hr className="mt-3" />
                  <div className="form-group">
                    <label>
                      <b>Step 3. Sibling information</b>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sibling</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Sibling"
                        name="description"
                        {...register("sibling")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Sibling Grade</label>
                      <select
                        className="form-control"
                        name="grade"
                        {...register("sibling_grade")}
                      >
                        <option value="">Choose Grade</option>
                      {gradelist?.length > 0 ? (
                        gradelist.map((elem) => (
                          <option key={elem?.id} value={elem?.id}>
                            {elem.name === 0
                              ? `Year ${elem.name} (kindy)`
                              : `Year ${elem.name}`}
                          </option>
                        ))
                      ) : (
                        <option>No result</option>
                      )}
                      <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                                    <hr className='mt-3' />
                                    <div className="form-group">
                                        <label><b>Step3. Choose a user name and password</b></label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">

                                        <label>User Name(Id): *</label>
                                        <input type="text" className="form-control" name="name"
                                            {...register('email', { required: true })}
                                        />
                                        {errors.email && errors.email.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div> */}
                  {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <div className='ui button mt-4' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Check Availability of this ID</div>
                                    </div>
                                </div> */}
                  {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password: *</label>
                                        <input type="password" className="form-control" name="password"
                                            {...register("password", {
                                                required: true,
                                                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                                            })}
                                        />
                                        {errors.password && errors.password.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Password: *</label>
                                        <input type="password" className="form-control" name="confirmPassword"
                                            {...register("confirmPassword", {
                                                required: true,
                                                validate: value =>
                                                    value === password.current || "The passwords do not match"
                                            })}
                                        />
                                        {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.confirmPassword && <p className="validation-error">{errors.confirmPassword.message}</p>}
                                    </div>
                                </div> */}
                  <br />
                </div>
                <div className="modal-footer">
                  <Button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          ) : (
            <DataLoader />
          )}
        </div>
      
    </>
  );
};
export default EditMember;
