import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { grade_all } from "../../../apis/Grade";
import { Assesment_add } from "../../../apis/Assesment.jsx";
import { get_question } from "../../../apis/testManagement/Question.jsx";
import { handleNavigation } from "../../../Utils/Utils.js";
const Addassesment = () => {
  const [question, setQuestion] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors,isDirty },
    reset,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    let result = await Assesment_add(data);
    if (result.status) {
      return navigate("/admin/assesment/all");
    }
    reset();
  };

  useEffect(() => {
    const fetchData = async () => {
      const questionList = await get_question();
      setQuestion(questionList?.data);
    };
    fetchData();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Paid Assessment</h4>
          </div>
          <div className="col-md-6 text-end">
            <div className="modify-right">
              <Link
                className="edit-btn"
                to={`/admin/assesment/all`}
                onClick={(e) => {handleNavigation(e,isDirty) }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <form
          className="form-horizontal"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Questionaire</label>
                <select
                  className="form-control form-select"
                  {...register("question_id")}
                >
                  <option value="">CHOOSE QUESTION</option>
                  {question &&
                    question?.map((elem) => {
                      return (
                        <option value={elem?.id}>{elem?.question_name}</option>
                      );
                    })}
                </select>
              </div>
              {errors.question_id && errors.question_id.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Price</label>
                <input
                  type="text"
                  className="form-control"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("price")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("title")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>status</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    {...register("status", { required: true })}
                    id="inlineRadio1"
                    value="1"
                    defaultChecked
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    {...register("status", { required: true })}
                    id="inlineRadio2"
                    value="0"
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Inactive
                  </label>
                </div>
              </div>
              {errors.status && errors.status.type === "required" && (
                <span className="validation-error">This is required</span>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Addassesment;
