import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { course_add } from '../../../api/Course/Course';
const AddCourse = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await course_add(data);
        if (result.status) {
            return navigate('/branch/class-mangaments/all');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Course</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal"
                        onSubmit={handleSubmit(onSubmit)} >
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>course Name</label>
                                    <input type="text" className="form-control"
                                        {...register('name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>status</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    )
}

export default AddCourse;
