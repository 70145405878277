import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import {
  Selective_trial_test_all,
  Selective_trial_test_destroy,
} from "../../../../apis/Selective_trial_test";
import CustomTable from "../../CustomTable";
import EditTrialTest from "./Edit";
import { getUserPermission } from "../../../../Utils/Utils";

const AllTrialTest = () => {
  const [grade, setGrade] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const permissions = getUserPermission("SelectiveTrialTest");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Grade",
      selector: "name",
      sortable: true,
    },
    {
      name: "Question Name",
      selector: "question_name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    const get_data = async () => {
      try {
        const result = await Selective_trial_test_all();
        const arrayData = result?.data?.map((elem, index) => {
          const { id, delete_status, name, question_name } = elem;
          return {
            no: `#${index + 1}`,
            question_name: question_name,
            status: delete_status === "0" ? "Inactive" : "Active",
            name:
              name === 0 ? `Year ${elem.name} (kindy)` : `Year ${elem.name}`,
            action: (
              <div data-tag="allowRowEvents">
                {permissions?.edit === 1 ? (
                  <button
                    onClick={() => {
                      setDuplicateItemId(id);
                      setIsModalOpen(true);
                      setStatus("grade");
                    }}
                    className="dlt-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                ) : (
                  ""
                )}
                {permissions?.delete === 1 ? (
                  <button
                    className="dlt-btn"
                    onClick={() =>
                      destroy(id, delete_status == "1" ? 0 : 1, question_name)
                    }
                  >
                    {delete_status == 0 ? (
                      <i class="fa-solid fa-check"></i>
                    ) : (
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    )}
                  </button>
                ) : (
                  ""
                )}
              </div>
            ),
          };
        });
        setGrade(arrayData);
        setShowResults(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active  this Selective Trail Test  ?"
            : "Are you sure you want to  inactive  this Selective Trail Test  ? "
        }`
      );
      if (result) {
        await Selective_trial_test_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
            {showResults ? (
              <>
              <div className="row">
                <div className="col-md-4">
                  <h4 className="modal-title">Selective Trial Test List</h4>
                </div>
                <div className="col-md-8">
                  {permissions?.add === 1 ? (
                    <Link
                      className="ui button float-end"
                      to="/admin/course-managment/selective-trial-test/add"
                    >
                      Add Selective Trial Test
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
                <CustomTable columns={columns} data={grade} />
              </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditTrialTest
        duplicateItemId={duplicateItemId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        status={status}
        setStatus={setStatus}
      />
    </>
  );
};
export default AllTrialTest;
