import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { class_all, class_destroy } from "../../../api/Class";
import CustomTable from "../../pages/CustomTable";
import { destroy_user } from "../../../../apis/generalSetting/SubUsers";
import CustomTableWithChild from "../../../../componrnt/pages/CustomTableWithChild";

export const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Class Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Year",
    selector: "year",
    sortable: true,
  },
  {
    name: "Branch Name",
    selector: "branch_name",
    sortable: true,
  },
  {
    name: "Grade Name",
    selector: "grade_name",
    sortable: true,
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },

  // {
  //     name: 'Delete',
  //     selector: 'action',
  //     sortable: true,
  // }
];
let childColumns = [
  {
    name: "S.No",
    selector: (_, index) => index + 1,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => `${row.first_name} ${row.last_name}`,
    sortable: true,
  },
  {
    name: "Phone",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Grade",
    selector: (row) => `Year${row.grade_name}`,
    sortable: true,
  },
  {
    name: "Action",
    name: "Action",
    cell: (row) => {
      console.log('roww',row);
      return(
      <div data-tag="allowRowEvents">
        <Link
          class="edit-btn"
          to={`/branch/class-mangament/assigne-student-edit/${row?.id}/${row.class_id}`}
          // state={{
          //   currentStepActive: 2,
          //   isNotVisibleFirstStep: true,
          // }}
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </Link>

        {/* <button
          class="dlt-btn"
          //   onClick={() => {
          //     handleDeleteTerm(row.course_id, row.id);
          //   }}
        >
          {" "}
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button> */}
      </div>
      )
    },
   
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];
const AllClasses = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const branchid = localStorage.getItem("b_id");
  useEffect(() => {
    async function get_data() {
      const result = await class_all();
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          class_name,
          year,
          branch_name,
          grade_name,
          delete_status,
          grade_id,
          term_id,
          students,
        } = elem;
        return {
          no: `#${index + 1}`,
          name: class_name,
          year: year,
          branch_name: branch_name,
          grade_name: `year ${grade_name}`,
          terms: students,
          status: delete_status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              <Link
                class="edit-btn"
                to={`/branch/class-mangament/assigne-student/${grade_id}/${term_id}/${id}`}
              >
                <i class="fa fa-user-plus" title="assign the student"></i>
              </Link>
              <Link class="edit-btn" to={`/branch/class-mangament/edit/${id}`}>
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </Link>
              <button
                className="dlt-btn"
                onClick={() =>
                  destroy(id, delete_status === 1 ? 0 : 1, class_name)
                }
              >
                {delete_status === 0 ? (
                  <i class="fa-solid fa-check"></i>
                ) : (
                  <i class="fa fa-trash" aria-hidden="true"></i>
                )}
              </button>
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = {
        delete_status: status,
        title: name,
        deleted_by: branchid,
      };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active this class ?"
            : "Are you sure you want to inactive  this class ? "
        }`
      );
      if (result) {
        await class_destroy(data, id);
      }
      get_data();
    };
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Class List</h4>
            </div>
            <div className="col-md-8">
              <Link
                className="ui button float-end"
                to="/branch/class-mangament/add"
              >
                Add Class
              </Link>
            </div>
          </div>
          {/* <CustomTable columns={columns} data={data} /> */}
          <CustomTableWithChild
            columns={columns}
            data={data}
            childColumns={childColumns}
            type="class"
          />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllClasses;
