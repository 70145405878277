import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

export async function get_countries()
{
    try {
        const response = await fetch(HTTPURL + 'api/countries-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// export async function member_add(data) 
// {
//     try {
//         const response = await fetch('http://127.0.0.1:4040/api/student/student-signup', {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },    
//             body: JSON.stringify(data)
//         });
//         const responseData = await response.json();
//         if(responseData.status)
//         {
//             // toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }

export async function member_add(data) {
    try {
        const response = await fetch( HTTPURL + 'api/student/student-signup', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        console.log('data',data);
        const responseData = await response.json();
        if (responseData?.status) {
            toast.success(responseData?.message);
        }
        else {
            console.log('error');
            toast.error(responseData?.message);
        }
        return responseData;
        
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all members
export async function members_all(id) {
    try {
        const response = await fetch(HTTPURL + `api/student/student-all?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// get all enrolled members
export async function EnrollMembers_all() {
    try {
        const response = await fetch(HTTPURL + 'api/student/all-enrolled-students');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// member edit 
export async function member_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/student/student-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        toast.success(error.message);
        return error.message;
    }
}

// member update 
export async function member_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/student/student-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error);
        return error.message;
    }
}


// member delete
export async function member_destroy(id,data) 
{
    try {
        const response = await fetch(HTTPURL + `api/student/student-destroy?id=${id}`,{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function check_exit_id(data) 
{
    const para = {
        'query': data
    }
    try {
        const response = await fetch(HTTPURL + 'api/student/check-exist-id', {
            method: "POST",
            headers: { "Content-Type": "application/json" },    
            body: JSON.stringify(para)
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};

// get Consultancy List
export async function cousultancy_list() {
    try {
        const response = await fetch(HTTPURL + 'api/get-consulting');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student_enroll_list
export async function student_enroll_list(id) 

{
    try {
        const response = await fetch(HTTPURL + `api/branches/endrolled-student-details?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
export async function enroll_student_payment(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-update-to-enrolled', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_eligible_courses(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/enrollment-eligible-courses-branch?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}