import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";



// add test type
export async function add_testType(data) {
    try {
        const response = await fetch(HTTPURL + 'api/test-type-add2', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get test-typ-all data
export async function all_TestType_Data() {
    try {
        const response = await fetch(HTTPURL + 'api/test-typ2-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//edit test type
export async function test_Type_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/test-type-edit2?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// update test type
export async function test_Type_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/test-type-update2?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// soft delete
export async function test_Type_destroy(id,data) {
    try {
        const response = await fetch(HTTPURL + `api/test-typ2-destroy?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}