import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {useForm } from "react-hook-form";
import { handleNavigation } from "../../../../Utils/Utils";
import {
  get_enrolled_course_status,
  edit_assigne_student_data,
  get_notAssinge_courses,
  update_enrolled_course_date,
} from "../../../api/branches/Branch";
import { Button } from "semantic-ui-react";
import AssigneStudent from "./AssigneStudent";
const EditAssigneStudent = () => {
  const navigate = useNavigate();
  const [selectedTermsByCourse, setSelectedTermsByCourse] = useState({});
  const [termSelectedUnitsByCourse, setTermSelectedUnitsByCourse] = useState(
    {}
  );
  console.log('termSelectedUnitsByCourse',termSelectedUnitsByCourse);
  
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [activeTab, setActiveTab] = useState("edit");
  const [editData, setEditData] = useState([]);
  const [activeCourseId, setActiveCourseId] = useState(editData[0]?.course_id);
  const handleTabClick = (courseId) => {
    setActiveCourseId(courseId);
    setActiveTab('edit')
  };
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const { student_id ,class_id} = useParams();

  useEffect(() => {
    async function get_all_data() {
      const data = await edit_assigne_student_data(student_id);
      setEditData(data?.data);
      setActiveCourseId(data?.data[0]?.course_id)
    }
    get_all_data();
    get_enroll_data();
  }, []);
  useEffect(() => {
    const initializedData = {};
    editData.forEach((course) => {
      initializedData[course.course_id] = {};
      course.terms.forEach((term, termIndex) => {
        initializedData[course.course_id][termIndex] = {};
        term.subjects.forEach((subject, subjectIndex) => {
          initializedData[course.course_id][termIndex][subjectIndex] =
            subject.units
              .filter((unit) => unit.status === 1) // Only add selected units
              .map((unit) => unit.unit_id);
        });
      });
    });
    setTermSelectedUnitsByCourse(initializedData);
  }, [editData]);
  // enrolment status
  async function get_enroll_data() {
    if (selectedCourse) {
      const result = await get_enrolled_course_status(selectedCourse);
      const course_selected_data = result?.data || [];
      const termsByCourseId = {};
      course_selected_data.forEach((course) => {
        const courseId = course.course_id;
        if (!termsByCourseId[courseId]) {
          termsByCourseId[courseId] = {
            courseName: course.course_name,
            courseTypeName: course.course_type_name,
            terms: [],
          };
        }
        course.terms.forEach((term) => {
          termsByCourseId[courseId].terms.push({
            termName: term.term_name,
            subjects: term.subjects,
          });
        });
      });
      const preSelectedTermsByCourse = {};
      const preSelectedUnitsByCourse = {};
      Object.keys(termsByCourseId).forEach((courseId) => {
        const courseTerms = termsByCourseId[courseId].terms;
        preSelectedTermsByCourse[courseId] = [];
        preSelectedUnitsByCourse[courseId] = [];
        courseTerms.forEach((term, termIndex) => {
          let hasUnitsWithStatus1 = false;
          term.subjects.forEach((subject) => {
            subject.units.forEach((unit) => {
              if (unit.status === 1) {
                hasUnitsWithStatus1 = true;
              }
            });
          });
          if (hasUnitsWithStatus1) {
            preSelectedTermsByCourse[courseId].push(term.termName);
          }
          term.subjects.forEach((subject, subjectIndex) => {
            preSelectedUnitsByCourse[courseId][termIndex] = [];
            subject.units.forEach((unit) => {
              if (unit.status === 1) {
                preSelectedUnitsByCourse[courseId][termIndex].push(
                  unit.unit_id
                );
              }
            });
          });
        });
      });
      setSelectedTermsByCourse(preSelectedTermsByCourse);
      setTermSelectedUnitsByCourse(preSelectedUnitsByCourse);
    }
  }
  const handleLectureInputChange = (
    e,
    courseId,
    termIndex,
    subjectIndex,
    unitId
  ) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };

      // Ensure course, term, and subject exist in the state
      if (!updatedUnits[courseId]) updatedUnits[courseId] = {};
      if (!updatedUnits[courseId][termIndex])
        updatedUnits[courseId][termIndex] = {};
      if (!updatedUnits[courseId][termIndex][subjectIndex])
        updatedUnits[courseId][termIndex][subjectIndex] = [];

      const isUnitSelected =
        updatedUnits[courseId][termIndex][subjectIndex].includes(unitId);

      // Toggle unit selection
      if (isUnitSelected) {
        updatedUnits[courseId][termIndex][subjectIndex] = updatedUnits[
          courseId
        ][termIndex][subjectIndex].filter((id) => id !== unitId);
      } else {
        updatedUnits[courseId][termIndex][subjectIndex].push(unitId);
      }

      // Clean up empty selections
      if (updatedUnits[courseId][termIndex][subjectIndex].length === 0) {
        delete updatedUnits[courseId][termIndex][subjectIndex];
      }
      if (Object.keys(updatedUnits[courseId][termIndex]).length === 0) {
        delete updatedUnits[courseId][termIndex];
      }
      if (Object.keys(updatedUnits[courseId]).length === 0) {
        delete updatedUnits[courseId];
      }

      return updatedUnits;
    });
  };

  const handleTermCheckboxChange = (courseId, termIndex) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };
      const course = editData.find((course) => course.course_id === courseId);
      if (!course) {
        console.error(`Course with ID ${courseId} not found in editData.`);
        return updatedUnits;
      }
      const term = course.terms[termIndex];
      if (!term) {
        console.error(`Term index ${termIndex} not found for course ID ${courseId}.`);
        return updatedUnits;
      }
      const allUnitsInTerm = term.subjects.flatMap((subject) =>
        subject.units.map((unit) => unit.unit_id)
      );
      if (!updatedUnits[courseId]) updatedUnits[courseId] = {};
      if (!updatedUnits[courseId][termIndex]) updatedUnits[courseId][termIndex] = {};
      const allUnitsSelected = term.subjects.every((subject, subjectIndex) =>
        allUnitsInTerm.every((unitId) =>
          updatedUnits[courseId][termIndex]?.[subjectIndex]?.includes(unitId)
        )
      );
  
      if (allUnitsSelected) {
        delete updatedUnits[courseId][termIndex];
      } else {
        term.subjects.forEach((subject, subjectIndex) => {
          updatedUnits[courseId][termIndex][subjectIndex] = subject.units.map(
            (unit) => unit.unit_id
          );
        });
      }
      if (Object.keys(updatedUnits[courseId][termIndex] || {}).length === 0) {
        delete updatedUnits[courseId][termIndex];
      }
      if (Object.keys(updatedUnits[courseId] || {}).length === 0) {
        delete updatedUnits[courseId];
      }
      return updatedUnits;
    });
  };
  
  
  const onSubmit = async () => {
    const submissionData = [];
    editData.forEach((course) => {
      course.terms.forEach((term, termIndex) => {
        const termUnits =
          termSelectedUnitsByCourse[course.course_id]?.[termIndex];
        if (termUnits) {
          term.subjects.forEach((subject, subjectIndex) => {
            const selectedUnits = termUnits[subjectIndex];
            if (selectedUnits?.length) {
              selectedUnits.forEach((unitId) => {
                submissionData.push({
                  course_id: course.course_id,
                  termName: term.term_name,
                  subject_id: subject.subject_id,
                  unit_id: unitId,
                });
              });
            }
          });
        }
      });
    });
    // Create the payload
    const payload = {
      student_id: student_id,
      courses: submissionData,
      newcourse:'',
    };
    const result = await update_enrolled_course_date(payload);
    if (result?.status === true) {
      navigate("/branch/class-mangament/all");
    } else if (result?.statusCode === 403) {
      get_enroll_data();
    }
  };

  const handleAddNewCourse = () => {
    setActiveTab("add");
  };
  const combinePreviousData = (newData, previousData) => {
    const combinedData = [...newData];
  
    Object.keys(previousData || {}).forEach((courseId) => {
      Object.keys(previousData[courseId] || {}).forEach((termIndex) => {
        const termSubjects = previousData[courseId][termIndex];
  
        // Fetch the termName directly from editData
        const termName = editData
          ?.find((course) => course.course_id == courseId)
          ?.terms?.[termIndex]?.term_name || null;
  
        Object.keys(termSubjects || {}).forEach((subjectIndex) => {
          const units = termSubjects[subjectIndex] || [];
          units.forEach((unitId) => {
            if (
              !newData.some(
                (entry) =>
                  entry.course_id === courseId &&
                  entry.unit_id === unitId &&
                  entry.subject_id == subjectIndex
              )
            ) {
              combinedData.push({
                course_id: courseId,
                termName, // Use termName fetched from editData
                subject_id: subjectIndex,
                unit_id: unitId,
              });
            }
          });
        });
      });
    });
  
    return combinedData;
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <section className="courses-sec">
          <div className="modal-header">
            <h4 className="modal-title">Assign Student</h4>
            <Link
              className="edit-btn"
              to={`/branch/class-mangament/all`}
              onClick={(e) => {
                handleNavigation(e, isDirty);
              }}
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </Link>
          </div>
        </section>
        <div>
          <section className="courses-sec">
            <div className="modal-header">
              {/* <h4 className="modal-title">Enrolment Status</h4> */}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="courses-content">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          {editData?.map((course, index) => (
                            <li
                              key={course.course_id}
                              className="nav-item"
                              role="presentation"
                            >
                              <button
                                className={`nav-link ${
                                  activeCourseId === course.course_id
                                    ? "active"
                                    : ""
                                }`}
                                id={`course-tab-${course.course_id}`}
                                data-bs-toggle="tab"
                                data-bs-target={`#course-${course.course_id}`}
                                type="button"
                                role="tab"
                                aria-controls={`course-${course.course_id}`}
                                aria-selected={
                                  activeCourseId === course.course_id
                                }
                                onClick={() => handleTabClick(course.course_id)} 
                              >
                                {course.course_name} (ID: {course.course_id})
                              </button>
                            </li>
                          ))}
                             <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              activeTab === "add" ? "active" : ""
                            }`}
                            id="add-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#add-content"
                            type="button"
                            role="tab"
                            aria-controls="add-content"
                            aria-selected={activeTab === "add"}
                            onClick={() => (termSelectedUnitsByCourse ? '' :'' , handleAddNewCourse())}
                          >
                            + Add New Course
                          </button>
                        </li>
                        </ul>
                     
                        {/* Dropdown for adding new courses */}
                        {/* <li className="nav-item dropdown dropdown_right">
                          <label>Select Course *</label>
                          <select
                            className="form-select form-control"
                            onChange={(e) => setSelectedCourse(e.target.value)}
                          >
                            <option value="">+ Add Course</option>
                            {eligibleCourses?.map((course) => (
                              <option key={course.id} value={course.id}>
                                {course.name}
                              </option>
                            ))}
                          </select>
                        </li> */}
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-content" id="myTabContent">
                          {editData?.map((course, index) => (
                            <div
                              key={course.course_id}
                              className={`tab-pane fade ${
                                activeCourseId === course.course_id
                                  ? "show active"
                                  : ""
                              }`}
                              id={`course-${course.course_id}`}
                              role="tabpanel"
                              aria-labelledby={`course-tab-${course.course_id}`}
                            >
                              <h4>{course.course_name}</h4>
                              <h5>{course.course_type_name}</h5>
                              <div className="accordion" id="accordionExample">
                                {course.terms.map((term, termIndex) => {
                                  const isTermSelected = term.subjects.every(
                                    (subject, subjectIndex) =>
                                      subject.units.every((unit) =>
                                        termSelectedUnitsByCourse[
                                          course.course_id
                                        ]?.[termIndex]?.[
                                          subjectIndex
                                        ]?.includes(unit.unit_id)
                                      )
                                  );

                                  return (
                                    <div
                                      key={term.term_id}
                                      className="accordion-item"
                                    >
                                      <div className="terms-sec">
                                        <h2
                                          className="accordion-header"
                                          id={`heading-${term.term_id}`}
                                        >
                                          <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${term.term_id}`}
                                            aria-expanded={
                                              termIndex === 0 ? true : false
                                            }
                                            aria-controls={`collapse-${term.term_id}`}
                                          >
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={isTermSelected}
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                } // Prevent accordion toggle
                                                onChange={() =>
                                                  handleTermCheckboxChange(
                                                    course.course_id,
                                                    termIndex
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={`term-checkbox-${term.term_id}`}
                                              >
                                                <b>Term: {term.term_name}</b>
                                              </label>
                                            </div>
                                          </button>
                                        </h2>

                                        <div
                                          id={`collapse-${term.term_id}`}
                                          className={`accordion-collapse collapse ${
                                            termIndex === 0 ? "show" : ""
                                          }`}
                                          aria-labelledby={`heading-${term.term_id}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Units</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {term.subjects.map(
                                                (subject, subjectIndex) => (
                                                  <tr key={subject.subject_id}>
                                                    <td>
                                                      {subject.subject_name}
                                                    </td>
                                                    <td>
                                                      {subject.units.map(
                                                        (unit) => {
                                                          const isUnitSelected =
                                                            termSelectedUnitsByCourse[
                                                              course.course_id
                                                            ]?.[termIndex]?.[
                                                              subjectIndex
                                                            ]?.includes(
                                                              unit.unit_id
                                                            );

                                                          return (
                                                            <div
                                                              key={unit.unit_id}
                                                              className="form-check"
                                                            >
                                                              <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={
                                                                  isUnitSelected
                                                                }
                                                                onChange={(e) =>
                                                                  handleLectureInputChange(
                                                                    e,
                                                                    course.course_id,
                                                                    termIndex,
                                                                    subjectIndex,
                                                                    unit.unit_id
                                                                  )
                                                                }
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor={`checkbox-${unit.unit_id}`}
                                                              >
                                                                {unit.unit_name}{" "}
                                                                {unit.unit_id}
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             {activeTab === 'edit'? <Button
                type="submit"
                // disabled={subject?.length > 0 ? false : true}
              >
                Save
              </Button> : ''}
            </form>
            {/* Render newly added courses from termData */}
            {activeTab === "add" && (
              <div
                className="tab-pane fade show active"
                id="add-content"
                role="tabpanel"
                aria-labelledby="add-tab"
              >
                <AssigneStudent type={"edit"} previousData={termSelectedUnitsByCourse} student_id={student_id} combinePreviousData={combinePreviousData} edi_class_id={class_id} />
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditAssigneStudent;
