import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import { getUserPermission } from "../../../Utils/Utils";
import { all_deleted_records } from "../../../apis/Teachers";

const AllDeletedRecords = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Teachers");
  console.log("data", permissions);
  const columns = [
    {
      name: "No.",
      selector: "no",
      style: {
        width: '200px',
      },
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Role",
      selector: "roles",
      sortable: true,
    },
    {
      name: "Module",
      selector: "module",
      sortable: true,
    },
    {
      name: "Name",
      selector: "title",
      sortable: true,
    },
  ];
  useEffect(() => {
    async function get_data() {
      const result = await all_deleted_records();
      const arrayData = result?.data?.map((elem, index) => {
        const { user_id, first_name, roles, module, deleted_id,title } =
          elem;
        return {
          no: `#${index + 1}`,
          name: first_name,
          roles: roles,
          module: module,
          title: title,

        };
      });
      setData(arrayData, setShowResults(true));
    }
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Deleted Records</h4>
        </div>
        <div className="col-md-8">
        </div>
      </div>
        <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllDeletedRecords;
