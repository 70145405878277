import React from 'react';
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { subject_all } from '../../../api/Subject.jsx';
import { grade_all } from '../../../api/common.jsx';
import { get_category } from '../../../api/common.jsx';
import { get_terms } from '../../../api/common.jsx';
import { get_days } from '../../../api/common';
import { useState, useEffect } from 'react';
import { class_list_edit, brach_class_lesson_add, brach_class_lesson_data } from '../../../api/ClassList.jsx';
const EditClassList = () => {
    const [subjects, setSubject] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [file, setFile] = useState(null);
    const [days, setDays] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    // const [rowToDisPlay, setDowToDisPlay] = useState(0);
    const class_id = useParams();
    const b_name = localStorage.getItem('b_name')
    const branch_id = localStorage.getItem('b_id')
    const [formData, setFormData] = useState([]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState();


    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        // formState: { errors },
    } = useForm();

    const handleInputChange = (index, fieldName, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = { ...updatedFormData[index], [fieldName]: value };
        setFormData(updatedFormData);
    };

    const handleFileChange = (index, file) => {
        const updatedFormData = [...formData];
        updatedFormData[index] = { ...updatedFormData[index], file };
        setFormData(updatedFormData);
    };

    const get_user_change = (e) => {
        setRowCount(e.target.value)
        setFormData([...formData, {
            lessons: '', title: '', file: null, google_meet_link: '', offline: '',
            day: '', date: '', start_time: '', end_time: '', descriptions: ''
        }]);
    }


    const navigate = useNavigate();
    const onSubmit = async (e) => {
        e.preventDefault();
        const formDataObject = new FormData();
        formData.forEach((data, index) => {
            for (const key in data) {
                formDataObject.append(`formData[${index}][${key}]`, data[key]);
            }
        });
        let result = await brach_class_lesson_add(class_id, branch_id, formDataObject);
        if (result.status) {
            return navigate('/branch/classList/all');
        }
    }

    const fetchData = async () => {
        let res = await class_list_edit(class_id);
        setTimeout(() => {
            setValue('kind', res?.data?.course_id);
            setValue('subject_id', res?.data?.subject_id);
            setValue('term', res?.data?.term);
            setValue('grade', res?.data?.grade);
        }, 1000);
        setValue('year', res?.data?.year);
        setValue('movie_price', res?.data?.movie_price);
    }


    const lessonData = async () => {
        let res = await brach_class_lesson_data(class_id, branch_id);
        setFormData(res.data);
        setCount(res.data.length)
        // console.log('lesson', res?.data?.lessons);

    }


    useEffect(() => {
        async function get_data() {
            const result = await subject_all();
            const categorylist = await get_category();
            const gradeList = await grade_all();
            const termslist = await get_terms();
            const days = await get_days();
            setGradeList(gradeList?.data);
            setCategoryList(categorylist?.data);
            setSubject(result?.data);
            setTermsList(termslist?.data);
            setDays(days?.data);
        }
        get_data();
        fetchData();
        lessonData();
    }, []);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() );
    const TodayDate = maxDate.toISOString().split('T')[0];
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Class Detail</h4>
                </div>
                <div className="modal-body">

                    {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(1)} className={`nav-link ${currentStep === 1 ? 'active ' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Add Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(2)} className={`nav-link ${currentStep === 2 ? 'active ' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Online Google Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(3)} className={`nav-link ${currentStep === 3 ? 'active ' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Study Material</button>
                            </li>
                        </ul> */}
                    <div className="tab-content" id="pills-tabContent">
                        <form onSubmit={onSubmit}>
                            {currentStep === 1 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Course Type</label>
                                                <select className="form-select form-control" {...register("kind")} disabled>
                                                    <option value="">CHOOSE COURSE TYPE</option>
                                                    {
                                                        categoryList && categoryList?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.category_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <select className="form-control form-select" {...register("grade")} disabled>
                                                    <option value="">CHOOSE GRADE</option>
                                                    {
                                                        gradelist && gradelist?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Year</label>
                                                <select className="form-control form-select" {...register("year")} disabled>
                                                    <option value="">CHOOSE YEAR</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <select className="form-control form-select" {...register("term")} disabled>
                                                    <option value="">CHOOSE TERM</option>
                                                    {
                                                        termsList && termsList?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <select className="form-control form-select" {...register("subject_id")} disabled>
                                                    <option value="">CHOOSE SUBJECT</option>
                                                    {
                                                        subjects && subjects?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.subject}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Unit Price</label>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER UNIT PRICE'
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    {...register("movie_price")}
                                                    disabled /> $
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label>Branch Name</label>
                                                <input type="text" readOnly className="form-control" name="branchName" defaultValue={b_name} disabled />
                                            </div>

                                        </div>
                                    </div>
                                    {count > 0 ? <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                {/* <label><b>Type</b></label> */}
                                            </div>
                                            <input type="text" placeholder='NUMBER OF LESSONS' className='form-control me-3'
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                value={`TOTAL NUMBER OF LESSONS ${count}`} disabled />
                                            <br />
                                            <br />
                                            <div className='row'>
                                                {count > 0 ?
                                                    <div className="form-group">
                                                        <div className='row'>
                                                            {
                                                                formData?.map((elem, index, data) => {
                                                                    return (
                                                                        <>
                                                                            <div className='row' key={elem?.id}>
                                                                                <div className='col-md-6'>
                                                                                    <b>Lessons({index + 1})</b>
                                                                                    <input type="text"
                                                                                        name='lessons'
                                                                                        className="form-control lesson-input"
                                                                                        placeholder='ENTER LESSONS'
                                                                                        value={elem.lessons || data.lessons}
                                                                                        onChange={(e) => handleInputChange(index, 'lessons', e.target.value)}
                                                                                    />
                                                                                </div>

                                                                                <div className='col-md-6'>
                                                                                    <div className="form-group">
                                                                                        <label>Title</label>
                                                                                        <input type="text" className="form-control"
                                                                                            name='title'
                                                                                            placeholder='ENTER TITLE'       
                                                                                            value={elem.title || data.title}
                                                                                            onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='form-group'>
                                                                                        <label>File:</label>
                                                                                        <input
                                                                                            type="file" className='form-control'
                                                                                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='row'>
                                                                                                    <div className='col-md-3'>
                                                                                                        <img className='class-view' src={elem?.file} />
                                                                                                    </div>
                                                                                           
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Online Class </label>
                                                                                        <input type="text" className="form-control"
                                                                                            name='google_meet_link'
                                                                                            placeholder='ENTER ONLINE CLASS'
                                                                                            value={elem.google_meet_link || data.google_meet_link}
                                                                                            onChange={(e) => handleInputChange(index, 'google_meet_link', e.target.value)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>offline class</label>
                                                                                        <input type="text" className="form-control"
                                                                                            placeholder='ENTER OFFLINE CLASS'
                                                                                            name='offline'
                                                                                            value={elem.offline || data.offline}
                                                                                            onChange={(e) => handleInputChange(index, 'offline', e.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Day</label>
                                                                                        <select className="form-select form-control"
                                                                                            name='day'
                                                                                            value={elem.day || data.day}
                                                                                            onChange={(e) => handleInputChange(index, 'day', e.target.value)} >
                                                                                            <option value="">CHOOSE DAY</option>
                                                                                            {
                                                                                                days && days?.map((elem) => {
                                                                                                    return (<option value={elem?.id}>{elem?.name}</option>);
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className="form-group">
                                                                                        <label>Date</label>
                                                                                        <input type="date" className="form-control"
                                                                                            name='date'
                                                                                            max="9999-12-31"
                                                                                            placeholder='ENTER DATE'
                                                                                            value={elem.date || data.date}
                                                                                            onChange={(e) => handleInputChange(index, 'date', e.target.value)}

                                                                                     min={TodayDate}  />
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    {/* <div className='time'> */}

                                                                                    <label>Time</label>
                                                                                    <div className='row'>
                                                                                        <div className='col-md-6'>
                                                                                            <div className="form-group">
                                                                                                <label>Start</label>
                                                                                                <input type="time" className="form-control" name="start_time"
                                                                                                    value={elem.start_time || data.start_time}
                                                                                                    onChange={(e) => handleInputChange(index, 'start_time', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-6'>
                                                                                            <div className="form-group">
                                                                                                <label>End</label>
                                                                                                <input type="time" className="form-control" name='end_time'
                                                                                                    value={elem.end_time || data.end_time}
                                                                                                    onChange={(e) => handleInputChange(index, 'end_time', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Description</label>
                                                                                        <textarea className="form-control"
                                                                                            name='descriptions'
                                                                                            placeholder='ENTER DESCRIPTION'
                                                                                            rows="3"
                                                                                            value={elem.descriptions || data.descriptions}
                                                                                            onChange={(e) => handleInputChange(index, 'descriptions', e.target.value)}
                                                                                        ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </>
                                                                    )
                                                                })}
                                                        </div>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div> :
                                        <div className="row">
                                            <div className="col-12">
                                                <hr className='mt-3' />
                                                <div className="col-md-12">
                                                    {/* <label><b>Type</b></label> */}
                                                </div>
                                                <input type="text" placeholder='NUMBER OF LESSONS' className='form-control me-3'
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => get_user_change(e)} />
                                                <br />
                                                <br />
                                                <div className='row'>
                                                    {rowCount > 0 ?
                                                        <div className="form-group">

                                                            <div className='row'>

                                                                {
                                                                    Array.from({ length: rowCount })
                                                                        .map((elem, index, data) => {
                                                                            return (
                                                                                <>
                                                                                    <div className='row' key={elem?.id}>
                                                                                        <div className='col-md-6'>
                                                                                            <b>Lessons({index + 1})</b>
                                                                                            <input type="text"
                                                                                                name='lessons'
                                                                                                className="form-control lesson-input"
                                                                                                placeholder='ENTER LESSONS'
                                                                                                value={data.lessons}
                                                                                                onChange={(e) => handleInputChange(index, 'lessons', e.target.value)}
                                                                                            />
                                                                                        </div>

                                                                                        <div className='col-md-6'>
                                                                                            <div className="form-group">
                                                                                                <label>Title</label>
                                                                                                <input type="text" className="form-control"
                                                                                                    name='title'
                                                                                                    placeholder='ENTER TITLE'
                                                                                                    value={data.title}
                                                                                                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>File:</label>
                                                                                                <input
                                                                                                    type="file" className='form-control'
                                                                                                    onChange={(e) => handleFileChange(index, e.target.files[0])}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label>Online Class </label>
                                                                                                <input type="text" className="form-control"
                                                                                                    name='google_meet_link'
                                                                                                    placeholder='ENTER ONLINE CLASS'
                                                                                                    value={data.google_meet_link}
                                                                                                    onChange={(e) => handleInputChange(index, 'google_meet_link', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label>offline class</label>
                                                                                                <input type="text" className="form-control"
                                                                                                    placeholder='ENTER OFFLINE CLASS'
                                                                                                    name='offline'
                                                                                                    value={data.offline}
                                                                                                    onChange={(e) => handleInputChange(index, 'offline', e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="form-group">
                                                                                                <label>Day</label>
                                                                                                <select className="form-select form-control"
                                                                                                    name='day'
                                                                                                    value={data.day}
                                                                                                    onChange={(e) => handleInputChange(index, 'day', e.target.value)} >
                                                                                                    <option value="">CHOOSE DAY</option>
                                                                                                    {
                                                                                                        days && days?.map((elem) => {
                                                                                                            return (<option value={elem?.id}>{elem?.name}</option>);
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className='col-md-6'>
                                                                                            <div className="form-group">
                                                                                                <label>Date</label>
                                                                                                <input type="date" className="form-control"
                                                                                                    name='date'
                                                                                                    max="9999-12-31"
                                                                                                    placeholder='ENTER DATE'
                                                                                                    value={data.date}
                                                                                                    onChange={(e) => handleInputChange(index, 'date', e.target.value)}

                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            {/* <div className='time'> */}

                                                                                            <label>Time</label>
                                                                                            <div className='row'>
                                                                                                <div className='col-md-6'>
                                                                                                    <div className="form-group">
                                                                                                        <label>Start</label>
                                                                                                        <input type="time" className="form-control" name="start_time"
                                                                                                            value={data.start_time}
                                                                                                            onChange={(e) => handleInputChange(index, 'start_time', e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-md-6'>
                                                                                                    <div className="form-group">
                                                                                                        <label>End</label>
                                                                                                        <input type="time" className="form-control" name='end_time'
                                                                                                            value={data.end_time}
                                                                                                            onChange={(e) => handleInputChange(index, 'end_time', e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <div className="form-group">
                                                                                                <label>Description</label>
                                                                                                <textarea className="form-control"
                                                                                                    name='descriptions'
                                                                                                    placeholder='ENTER DESCRIPTION'
                                                                                                    rows="3"
                                                                                                    value={data.descriptions}
                                                                                                    onChange={(e) => handleInputChange(index, 'descriptions', e.target.value)}
                                                                                                ></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                            </div>
                                                        </div> : ''}
                                                </div>
                                            </div>
                                        </div>}

                                </div>
                            )}
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </form>
                        {/* {currentStep === 2 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className='col-md-3 col-sm-12'>
                                                <div className='form-group mt-4'>
                                                    <label>Branch</label>
                                                    <input type="text" readOnly className="form-control" name="time_start" {...register("branch")} defaultValue={b_name} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>Day</label>
                                                    <select className="form-select form-control" {...register("day", { required: true })}>
                                                        <option value="">CHOOSE DAY</option>
                                                        {
                                                            days && days?.map((elem) => {
                                                                return (<option value={elem?.id}>{elem?.name}</option>);
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {errors.day && errors.day.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <div className='time'>
                                                    <div className="form-group">
                                                        <label>Class Time</label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label>Start</label>
                                                                <input type="time" className="form-control" name="time_start" {...register("time_start", { required: true })} />
                                                            </div>
                                                            {errors.time_start && errors.time_start.type === "required" && <span className="validation-error">This is required</span>}
                                                            <div className='col-md-6'>
                                                                <label>End</label>
                                                                <input type="time" className="form-control" {...register("time_end", { required: true })} />
                                                            </div>
                                                            {errors.time_end && errors.time_end.type === "required" && <span className="validation-error">This is required</span>}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Google Meet Code <span style={{ color: 'red' }}> (* Code Only)</span></label>
                                                    <span>https://meet.google.com/</span>
                                                    <input type="text" className="form-control"
                                                        placeholder='ENTER GOOGLE MEET CODE'
                                                        {...register("google_meet_code", { required: true })} />
                                                </div>
                                                {errors.google_meet_code && errors.google_meet_code.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            )} */}
                        {/* {currentStep === 3 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Material</label>
                                                    <input type="file" className="form-control"
                                                        multiple {...register("study_file", { required: true })} onChange={(e) => handleFileChange(e)} />

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )} */}
                    </div>
                    {/* <div className="modal-footer">
                            {currentStep > 1 && (
                                <button type="button" className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Previous
                                </button>
                            )}
                            {currentStep < 3 && (
                                <button type="submit" className="btn btn-primary">
                                    Next
                                </button>
                            )}
                            {currentStep === 3 && (
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            )}
                        </div>  */}
                </div>

            </div>
        </div >
    )
}

export default EditClassList;
