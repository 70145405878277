import React, { useEffect, useState } from "react";
import AllEvalutation from "./All";

const EditEvaluation = ({
  setEvaluation,
  singleTypeFormdata,
  testTypeData,
  term_id,course_id,
  CallEvaluationList,
  year,
  grade_id
}) => {
    
  const [forms, setForms] = useState([]);

  useEffect(() => {
    const initializeEvaluations = () => {
      const initialEvaluations = [
        {
          test_name: "",
          test_type: "",
          test_kind: "",
          test_no: "",
          test_total_ques: "",
          test_total_time: "",
          tstm_guid: "",
          jwp_media_id: "",
          expiration_date: "",
          status: "",
        },
      ];
      setForms(initialEvaluations);
      setEvaluation(initialEvaluations);
    };

    initializeEvaluations();
  }, [testTypeData, setEvaluation]);

  const handleEvaluationInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      [name]: value,
    };
    setForms(updatedForms);
    setEvaluation(updatedForms);
  };

  const addMoreEvaluation = () => {
    const newEvaluation = {
      test_name: "",
      test_type: "",
      test_kind: "",
      test_no: "",
      test_total_ques: "",
      test_total_time: "",
      tstm_guid: "",
      jwp_media_id: "",
      expiration_date: "",
      status: "",
    };
    setForms([...forms, newEvaluation]);
    setEvaluation([...forms, newEvaluation]);
  };
  return (
    <div className="accordion" id="accordionExample">
      <div className="modal-body table-responsive">
        <AllEvalutation term_id={term_id} course_id={course_id} CallEvaluationList={CallEvaluationList} year={year} grade_id={grade_id}/>
        <div className="row">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Test Name/Title</th>
                <th >Test Type</th>
                <th>Test Kind</th>
                <th>Test No</th>
                <th>Test Total Questions</th>
                <th>Test Total Time</th>
                <th>TSTM GUID</th>
                <th>JWP Media ID</th>
                <th>Expiration date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {forms?.map((form, index) => (
                <tr key={`evaluation-${index}`}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_name"
                      value={form.test_name || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      name="test_type"
                      value={form.test_type || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    >
                      <option value="" >
                        Select Test Type
                      </option>
                      {testTypeData?.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type?.test_title}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                      <select
                      className="form-select"
                      name="test_kind"
                      value={form.test_kind || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    >
                      <option value="" >Select test kind</option>
                      <option value="objective" >Objective</option>
                      <option value="selective" >Selective</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_no"
                      value={form.test_no || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_ques"
                      value={form.test_total_ques || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="test_total_time"
                      value={form.test_total_time || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="tstm_guid"
                      value={form.tstm_guid || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      name="jwp_media_id"
                      value={form.jwp_media_id || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      name="expiration_date"
                      value={form?.expiration_date || ""}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    />
                  </td>
                  <td>
                  <select
                      className="form-select"
                      name="status"
                      value={form.status}
                      onChange={(e) =>
                        handleEvaluationInputChange(e, index)
                      }
                    >
                      <option >Status</option>
                      <option  value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button type="button" className="evaluation_btn_footer" onClick={()=>addMoreEvaluation()}>Add more</button>
      </div>
    </div>
  );
};

export default EditEvaluation;
