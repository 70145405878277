import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { get_Category } from "../../../../../src/apis/Catergory";
import {
  get_SubCategory,
  SubCategory_destroy,
  get_SubCategory_by_id,
} from "../../../../apis/Catergory";
import EditSubCategory from "./Edit";
import CustomTable from "../../CustomTable";
import { getUserPermission } from "../../../../Utils/Utils";

const AllSubCategory = () => {
  const [grade, setGrade] = useState([]);
  const [allcategory, setallcategory] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [status, setStatus] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicateItemId, setDuplicateItemId] = useState(null);
  const permissions = getUserPermission('SubCategory');
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
    },
    {
      name: "Sub Category",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 ||
    permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  const handleChange = async (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    setInputValue(event.target.value);
    const selectedValue = event.target.value;
    if (!selectedValue) {
      return get_data();
    }
    const result = await get_SubCategory_by_id(selectedValue);
    const arrayData = result?.data?.map((elem, index) => {
      const { id, name, status } = elem;
      return {
        no: `#${index + 1}`,
        name: name,
        status: status == 0 ? "Inactive" : "Active",
        category: selectedOption.text,
        action: (
          <div data-tag="allowRowEvents">
            <button
              onClick={() => {
                setDuplicateItemId(id);
                setIsModalOpen(true);
                setStatus("grade");
              }}
              className="dlt-btn"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              {" "}
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button className="dlt-btn" onClick={() => destroy(id)}>
              {" "}
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        ),
      };
    });
    setGrade(arrayData, setShowResults(true));
  };
  async function get_cat() {
    const allData = await get_Category();
    const finalReuslt = allData.data;
    setallcategory(finalReuslt, setShowResults(true));
  }
  async function get_data() {
    const result = await get_SubCategory();
    const arrayData = result?.data?.map((elem, index) => {
      const { id, name, delete_status, category } = elem;
      return {
        no: `#${index + 1}`,
        name: name,
        status: delete_status == 0 ? "Inactive" : "Active",
        category: category,
        action: (
          <div data-tag="allowRowEvents">
            {
              permissions?.edit ===1?
            <button
              onClick={() => {
                setDuplicateItemId(id);
                setIsModalOpen(true);
                setStatus("grade");
              }}
              className="dlt-btn"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              {" "}
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>:''
            }
            { permissions?.delete===1 ?
            <button className="dlt-btn" onClick={() => destroy(id, delete_status == "1" ? 0 : 1, name)}>
              {" "}
              {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
            </button>:''
            }
          </div>
        ),
      };
    });
    setGrade(arrayData, setShowResults(true));
  }
  const destroy = async (id, status, name) => {
    const data = { delete_status: status, title: name, deleted_by: UserID };
    const result = window.confirm(
      `${
        status === 1
          ? "Are you sure you want to active  this Sub Categroy  ?"
          : "Are you sure you want to  inactive  this Sub Categroy  ? "
      }`
    );
    if (result) {
      await SubCategory_destroy(id, data);
    }
    get_data();
  };
  useEffect(() => {
    get_data();
    get_cat();
  }, []);
  return (
    <>
      <div className="modal-content">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="right-nav" id="dash-event">
            {showResults ? (
              <>
              <div className="row">
                <div className="col-md-4">
                  <h4 className="modal-title">Sub Category List</h4>
                </div>
                <div className="col-md-8">
                    {permissions?.add ===1 ?
                  <Link
                    className="ui button float-end"
                    to="/admin/general-setting/sub-category/add"
                  >
                    Add Sub Category
                  </Link>:''
                    }
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Category Filter</label>
                    <select
                      className="form-select form-control"
                      name="category"
                      value={inputValue}
                      onChange={handleChange}
                    >
                      <option value="">Choose Category</option>
                      {allcategory?.length > 0 ? (
                        allcategory &&
                        allcategory?.map((elem) => {
                          return <option value={elem?.id}>{elem?.name}</option>;
                        })
                      ) : (
                        <option>No result</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
                <CustomTable columns={columns} data={grade} />
              </>
              ) : (
                <DataLoader />
              )}
            </div>
          </div>
        </div>
      </div>
      <EditSubCategory
        duplicateItemId={duplicateItemId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        status={status}
        setStatus={setStatus}
      />
    </>
  );
};
export default AllSubCategory;
