import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import { teacher_all } from "../../../apis/Teachers";
import { branch_all } from "../../../apis/Branches";
import { getUserPermission } from "../../../Utils/Utils";

const Attendance = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Attendance");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.view === 1
      ? {
          name: "Action",
          selector: "action",
          sortable: true,
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await branch_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, branch_name, teacher_id, teacher_name, status, user_id } =
          elem;
        return {
          no: `#${index + 1}`,
          branch: branch_name,
          teacher_id: teacher_id,
          teacher_name: teacher_name,
          status: status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              <Link
                class="edit-btn"
                to={`/admin/students/attendance/view/${id}`}
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </Link>
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Attendance </h4>
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default Attendance;
