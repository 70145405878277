import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import CustomTable from "../CustomTable";
import { teacher_all, teacher_destroy } from "../../../api/Teacher";
import { getUserPermission } from "../../../../Utils/Utils";

const AllTeachers = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const b_id = localStorage.getItem("b_id");
  const permission = getUserPermission("Teachers");
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Branch",
      selector: "branch",
      sortable: true,
    },
    {
      name: "TeacherId",
      selector: "teacher_id",
      sortable: true,
    },
    {
      name: "Teacher Name",
      selector: "teacher_name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permission?.edit === 1 || permission?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await teacher_all(b_id);
      const arrayData = result?.data?.map((elem, index) => {
        const { id, branch_name, teacher_id, teacher_name, delete_status, user_id } =
          elem;
        return {
          no: `#${index + 1}`,
          branch: branch_name?.toLowerCase(),
          teacher_id: teacher_id,
          teacher_name: teacher_name.toLowerCase(),
          status: delete_status == 0 ? "Inactive" : "Active",
          action: (
            <div data-tag="allowRowEvents">
              {permission?.edit === 1 ? (
                <Link className="edit-btn" to={`/branch/teacher/edit/${id}`}>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permission?.delete === 1 ? (
                <button className="dlt-btn" onClick={() => destroy(user_id,delete_status == '1' ? 0 : 1,teacher_name)}>
                  {" "}
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
              {permission?.view === 1 ? (
                <Link
                  className="edit-btn"
                  to={`/branch/student-attendance/view/${user_id}`}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
  get_data();
    const destroy = async (id,status,name) => {
      const dataa= { delete_status: status,title:name,deleted_by:b_id };
      const result = window.confirm(
        `${
          data === 1
            ? "Are you sure you want to inactive this teacher ?"
            : "Are you sure you want to active this teacher ? "
        }`
      );
      if (result) {
        const res = await teacher_destroy(id, dataa);
        console.log("res", res);
        if (res?.data) {
          get_data();
        }
      }
      get_data();
    };
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="modal-title">Teacher List</h4>
        </div>
        <div className="col-md-8">
         {permission?.add === 1 ? <Link className="ui button float-end" to="/branch/teacher/add">
            Add Teacher
          </Link>:''}
        </div>
      </div>
        <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllTeachers;
