import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataLoader from "../../../../Utils/DataLoader";
import { discout_destroy, discout_all } from "../../../../apis/Discount";
import CustomTable from "../../CustomTable";
import { getUserPermission } from "../../../../Utils/Utils";
const AllDiscount = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const permissions = getUserPermission("Discounts");
  const UserID = JSON.parse(localStorage.getItem("userData"))?.data?.id;
  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
    },
    {
      name: "Name",
      selector: "discount_list",
      sortable: true,
    },
    {
      name: "Percentage",
      selector: "percentage",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    permissions?.edit === 1 || permissions?.delete === 1
      ? {
          name: "Action",
          selector: "action",
        }
      : "",
  ];
  useEffect(() => {
    async function get_data() {
      const result = await discout_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, name, fees, delete_status, percentage } = elem;
        return {
          no: `#${index + 1}`,
          discount: `AUD ${fees}`,
          discount_list: name,
          percentage: `${percentage}%`,
          status: delete_status == "0" ? "INACTIVE" : "ACTIVE",
          action: (
            <div data-tag="allowRowEvents">
              {permissions?.edit === 1 ? (
                <Link
                  class="edit-btn"
                  to={`/admin/general-setting/discount/edit/${id}`}
                >
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </Link>
              ) : (
                ""
              )}
              {permissions?.delete === 1 ? (
                <button
                  className="dlt-btn"
                  onClick={() =>
                    destroy(id, delete_status == "1" ? 0 : 1, name)
                  }
                >
                  {delete_status == 0 ? (
                    <i class="fa-solid fa-check"></i>
                  ) : (
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          ),
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = async (id, status, name) => {
      const data = { delete_status: status, title: name, deleted_by: UserID };
      const result = window.confirm(
        `${
          status === 1
            ? "Are you sure you want to active  this discount ?"
            : "Are you sure you want to  inactive  this discount ? "
        }`
      );
      if (result) {
        await discout_destroy(id, data);
      }
      get_data();
    };
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      {showResults ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <h4 className="modal-title">Discount List</h4>
            </div>
            <div className="col-md-8">
              {permissions?.add === 1 ? (
                <Link
                  className="ui button float-end"
                  to="/admin/general-setting/discount/add"
                >
                  Add Discount
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <CustomTable columns={columns} data={data} />
        </>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};
export default AllDiscount;
